<template>
  <cs-app
    :show-header="isLoggedIn"
    :side-nav-width="sideNavWidth"
    :app-logo-uri="appBarLogo"
    :version-in-user-profile="version"
  >
    <template #header-menu> </template>

    <template #header-left> </template>

    <template #header-right> </template>

    <template #header-helper> </template>

    <template #side-nav>
      <left-navigation />
    </template>

    <template #router-view>
      <router-view v-slot="{ Component }">
        <v-slide-y-transition>
          <component :is="Component" />
        </v-slide-y-transition>
      </router-view>
    </template>

    <template #footer>
      <div class="content">
        <v-container>
          <v-row>
            <v-img :src="csLogo" class="cs-logo"> </v-img>
          </v-row>
          <v-row>
            <span> &copy;2022 CompliSpace ({{ version }}). All rights reserved. </span>
          </v-row>
        </v-container>
      </div>
    </template>
  </cs-app>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { CSBase } from '@complispace/cs-design-system';
import LeftNavigation from '@/navigations/LeftNavigation';
import csLogo from './assets/complispace-logo.png';
import ideagenCsLogo from './assets/ideagen-complispace-logo.svg';
import { version } from '../package.json';
import * as MutationTypes from './store/mutationTypes';

export default {
  name: 'App',
  components: {
    'left-navigation': LeftNavigation
  },

  extends: CSBase,

  data() {
    return {
      csLogo,
      hideSideNav: false,
      appBarLogo: ideagenCsLogo
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'authorization/isLoggedIn',
      apiVersion: 'app/apiVersion'
    }),

    ...mapState({
      viewTypeStore: 'view-type'
    }),

    sideNavWidth() {
      return this.isLoggedIn && !this.hideSideNav ? 300 : 0;
    },

    version() {
      return `v${version}#${this.apiVersion}`;
    }
  },

  created() {
    this.setViewTitle('CS User Directory');
  },

  async mounted() {
    document.title = this.$store.getters['appearance/viewTitle'] || 'Complispace User Directory';
    const appHostSplits = window.location.host.split('.');
    this.setAppName(appHostSplits[0].toUpperCase() || '');
    this.$store.dispatch('app/getApiVersion');
  },

  methods: {
    ...mapMutations({
      setPageViewType: MutationTypes.VIEW_TYPE_SET_PAGE_VIEW_TYPE,
      setAppName: MutationTypes.APP_SET_NAME
    })
  }
};
</script>

<style>
.v-footer.theme--light.v-sheet {
  background-color: white;
}

.v-footer {
  justify-content: space-around;
}
</style>
<style scoped>
.cs-logo {
  width: 200px;
  height: 40px;
}
</style>
