import omit from 'lodash/omit';

const removeUploadedUsersByKey = (localStorage, key) => (connectionName) => {
  const str = localStorage.getItem(key);
  const currentValue = str ? JSON.parse(str) : {};

  if (Object.keys(currentValue).includes(connectionName)) {
    const updatedValue = omit(currentValue, connectionName);
    localStorage.setItem(key, JSON.stringify(updatedValue));
  }
};

export default removeUploadedUsersByKey;
