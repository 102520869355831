import Assert from '@/helpers/assert';
import AssertError from '@/helpers/AssertError';
import isValidAuth0ConfigAppUrl from '@/helpers/isValidAuth0ConfigAppUrl';

const createOrUpdateAuth0ConfigByOrganizationId =
  (soulApiRequest) =>
  async (
    appUrl,
    orgId,
    promptOnLoginEnabled,
    sharedDeviceEnabled,
    connectionButtonsEnabled,
    connectionNames
  ) => {
    if (!isValidAuth0ConfigAppUrl(appUrl)) {
      throw new AssertError('Invalid url');
    }
    const payload = {
      appUrl,
      orgId: Assert.isPresent(orgId, 'auth0Config.orgId'),
      promptOnLoginEnabled: Assert.isPresent(
        promptOnLoginEnabled,
        'auth0Config.promptOnLoginEnabled'
      ),
      sharedDeviceEnabled: Assert.isPresent(sharedDeviceEnabled, 'auth0Config.sharedDeviceEnabled'),
      connectionButtonsEnabled: Assert.isPresent(
        connectionButtonsEnabled,
        'auth0Config.connectionButtonsEnabled'
      ),
      connectionNames: Assert.isArray(connectionNames, 'auth0Config.connectionNames')
    };
    const response = await soulApiRequest({
      url: '/auth0-config',
      method: 'put',
      data: payload
    });
    return response.data;
  };

export default createOrUpdateAuth0ConfigByOrganizationId;
