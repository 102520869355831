<template>
  <cs-form-dialog
    :id="id"
    ref="app-url-selector-dialog"
    class="app-url-selector-dialog"
    :heading="heading"
    :model-value="showDialog"
    :primary-action="{
      label: confirmLabel,
      disabled: !canContinue
    }"
    :secondary-action1="{
      label: cancelLabel
    }"
    @update:model-value="onCancel"
    @primary-click="onConfirm"
    @secondary1-click="onCancel"
  >
    <template #cs-form-dialog-content>
      <v-form ref="app-url-selector-form" class="app-url-selector-form">
        <app-url-selector
          :id="selectorId"
          class="app-url-selector"
          :label="label"
          :hint="hint"
          :rules="[rules.required(label)]"
          @change="onChange"
        ></app-url-selector>
      </v-form>
    </template>
  </cs-form-dialog>
</template>
<script>
import { CSFormDialog, CSBase } from '@complispace/cs-design-system';
import AppUrlSelector from '@/components/AppUrlSelector';

export default {
  name: 'AppUrlSelectorDialog',
  components: {
    'app-url-selector': AppUrlSelector,
    'cs-form-dialog': CSFormDialog
  },
  extends: CSBase,
  props: {
    confirmLabel: {
      type: String,
      default() {
        return 'Yes';
      }
    },

    cancelLabel: {
      type: String,
      default() {
        return 'No';
      }
    },

    selectorId: { type: String, required: false, default: '' },

    heading: {
      type: String,
      required: true
    },

    hint: {
      type: String,
      required: true
    },

    label: {
      type: String,
      default() {
        return 'Application Url';
      }
    },

    modelValue: {
      type: Boolean,
      required: true
    }
  },

  emits: ['app-url-confirm', 'app-url-cancel', 'update:modelValue'],

  data() {
    return {
      appUrl: '',
      id: this.$attrs.id,
      showDialog: this.modelValue,
      rules: {
        required: (label) => (v) => !!v || `${label} is required`
      }
    };
  },

  computed: {
    canContinue() {
      if (this.appUrl) {
        return true;
      }
      return false;
    }
  },

  watch: {
    modelValue(val) {
      this.showDialog = val;
    }
  },

  methods: {
    async isValid() {
      const validation = await this.$refs['app-url-selector-form'].validate();
      return validation.valid;
    },

    onChange(value) {
      this.appUrl = value;
    },

    async onConfirm() {
      const isValid = await this.isValid();
      if (isValid) {
        const selected = this.appUrl;
        this.$emit('app-url-confirm', selected);
      }
    },

    onCancel() {
      this.reset();
      this.appUrl = '';

      this.closeDialog();

      this.$emit('app-url-cancel');
    },

    closeDialog() {
      this.showDialog = false;
      this.$emit('update:modelValue', this.showDialog);
    },

    reset() {
      this.appUrl = '';
      this.$refs['app-url-selector-form'].reset();
    }
  }
};
</script>
<style scoped></style>
