import * as MutationTypes from '@/store/mutationTypes';
import { User } from '@/models';
import uniq from 'lodash/uniq';
import Assert from '@/helpers/assert';

const mutations = {
  [MutationTypes.SET_USERS_BY_CONNECTION_NAME](state, { users, itemsLength }) {
    state.users = users;
    state.itemsLength = itemsLength;
  },

  [MutationTypes.DE_ASSIGN_ROLE_BY_USER_ID](state, { userId, role }) {
    const user = state.users.findById(userId);
    if (user) {
      const roles = user.roles.filter((it) => it !== role);
      const updatedUser = User(
        user.appMetadata,
        user.blocked,
        user.email,
        user.emailVerified,
        user.groups,
        user.id,
        user.identities,
        user.loginsCount,
        user.name,
        user.picture,
        roles,
        user.surname,
        user.userMetadata
      );

      state.users = state.users.updateUser(updatedUser);
    }
  },

  [MutationTypes.ASSIGN_ROLES_BY_USER_ID](state, { userId, roles }) {
    Assert.isPresent(userId, 'userId');
    Assert.isArray(roles, 'roles');
    const user = state.users.findById(userId);

    if (user) {
      const updatedRoles = uniq(user.roles.concat(roles));
      const updatedUser = User(
        user.appMetadata,
        user.blocked,
        user.email,
        user.emailVerified,
        user.groups,
        user.id,
        user.identities,
        user.loginsCount,
        user.name,
        user.picture,
        updatedRoles,
        user.surname,
        user.userMetadata
      );

      state.users = state.users.updateUser(updatedUser);
    }
  }
};

export default mutations;
