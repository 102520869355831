<template>
  <cs-view single-view>
    <template #content>
      <div class="connection mt-3 mx-1">
        <div class="connection-title">
          <h3 id="connection-name-title">{{ connectionName }}</h3>
        </div>
        <div v-if="canShowContent" id="connection-page">
          <v-tabs v-model="tabIndex" color="primary" @update:model-value="onChangeTab">
            <v-tab v-if="isCompliSpaceStaff || isClientAdmin" id="connection_tab_user"
              ><span class="style-06-compact">Users</span></v-tab
            >
            <v-tab v-if="isCompliSpaceStaff" id="connection_tab_uploadUsers"
              ><span class="style-06-compact">Upload Users</span></v-tab
            >
          </v-tabs>
          <router-view></router-view>
        </div>
      </div>
    </template>
  </cs-view>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters, mapState } from 'vuex';
import * as MutationTypes from '@/store/mutationTypes';
import RouteName from '@/router/routeName';

import componentErrorHandler from '@/helpers/componentErrorHandler';

export default {
  name: 'ConnectionPage',

  components: {},

  extends: CSBase,

  props: {
    connectionName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      tabIndex: 0,
      TAB_NAME: {
        0: 'Users',
        1: 'UploadUsersCsv'
      }
    };
  },

  computed: {
    ...mapState({
      organization: (state) => state.organization.organization,
      connections: (state) => state.organization.connections || [],
      selectedConnection: (state) => state.organization.selectedConnection,
      csAlert: (state) => state['cs-appearance'].alert
    }),

    ...mapGetters({
      isCompliSpaceStaff: 'authorization/isCompliSpaceStaff',
      isClientAdmin: 'authorization/isClientAdmin'
    }),

    canShowContent() {
      return this.connections.length > 0 && this.isValidConnection();
    }
  },

  watch: {
    connectionName() {
      if (this.canShowContent) {
        this.clearAlert();
      }
    }
  },

  async mounted() {
    const message = localStorage.getItem('errorAlertAuthMessage');

    if (message) {
      componentErrorHandler(this, undefined, message, false);
      localStorage.removeItem('errorAlertAuthMessage');
    }

    try {
      if (this.connections.length === 0) {
        await this.$store.dispatch('organization/fetchConnections');
      }
    } catch (e) {
      componentErrorHandler(this, e, undefined, false);
    }

    if (!this.isValidConnection()) {
      await this.$router.push({
        name: RouteName.NOT_FOUND
      });
    } else {
      const connection = this.connections.find((conn) => conn.name === this.connectionName) || null;
      this.$store.commit(MutationTypes.ORGANIZATION_SET_SELECTED_CONNECTION, connection);
    }
    this.setTabIndex();
  },

  methods: {
    isValidConnection() {
      return this.connections.some((connection) => connection.name === this.connectionName);
    },

    async onChangeTab() {
      const curTab = this.TAB_NAME[this.tabIndex];
      const routeName = `Connection.${curTab}`;

      if (this.$route.name === routeName) {
        return;
      }
      const currentPage = Number.isNaN(parseInt(this.$route.query.page, 10))
        ? 1
        : parseInt(this.$route.query.page, 10);
      const currentItemsPerPage = Number.isNaN(parseInt(this.$route.query.itemsPerPage, 10))
        ? 10
        : parseInt(this.$route.query.itemsPerPage, 10);
      let query = {};

      if (routeName === RouteName.USERS) {
        query = {
          itemsPerPage: currentItemsPerPage,
          page: currentPage
        };
      }

      await this.$router.push({
        name: routeName,
        params: {
          connectionName: this.connectionName
        },
        query
      });
    },

    setTabIndex() {
      const routeName = this.$route.name;
      if (routeName === RouteName.USERS) {
        this.tabIndex = 0;
      } else if (routeName === RouteName.UPLOAD_USERS) {
        this.tabIndex = 1;
      }
    }
  }
};
</script>
<style scoped></style>
