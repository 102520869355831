import Assert from '@/helpers/assert';
import Pagination from '@/helpers/Pagination';
import { Organization } from '@/models';

const getOrganizations = (soulApiRequest) => async (itemsPerPage, page) => {
  Assert.isPresent(itemsPerPage, 'itemsPerPage');
  Assert.isPresent(page, 'page');

  const queryParams = {
    per_page: `${itemsPerPage}`
  };

  if (page > 0) {
    queryParams.page = `${page - 1}`;
  }

  const params = new URLSearchParams(queryParams);

  const response = await soulApiRequest({
    url: '/organizations',
    method: 'get',
    params
  });

  const organizations = Assert.isArray(response.data.organizations, 'response.data.organizations');

  const organizationList = organizations.map((organization) => {
    return Organization(
      organization.id,
      organization.name,
      organization.displayName,
      organization.roleDefinitions,
      organization.appUrls,
      organization.userDirectoryUrl
    );
  });

  return {
    organizations: organizationList,
    metadata: Pagination.extractMetadata(response.data, page, itemsPerPage)
  };
};

export default getOrganizations;
