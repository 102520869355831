import { Connection } from '@/models';
import Assert from '@/helpers/assert';

export const getConnectionsFromResponse = (response) => {
  const connections = Assert.isArray(response.data.connections, 'response.data.connections');

  return { connections };
};

const getConnectionsByOrganizationId = (soulApiRequest) => async (organizationId, strategy) => {
  Assert.isPresent(organizationId, 'organizationId');

  const tempParams = { organizationId };
  if (strategy) {
    tempParams.strategy = strategy;
  }

  const params = new URLSearchParams(tempParams);

  const response = await soulApiRequest({
    url: '/connections',
    method: 'get',
    params
  });

  const { connections } = getConnectionsFromResponse(response);

  return {
    connections: connections.map((it) => Connection(it.id, it.name, it.strategy))
  };
};

export default getConnectionsByOrganizationId;
