import Assert from '@/helpers/assert';

const deAssignRolesFromUserById = (soulApiRequest) => async (user, roles) => {
  Assert.isPresent(user, 'user');
  Assert.isPresent(user.id, 'user.id');

  Assert.isArray(user.roles, 'user.roles');
  Assert.isArray(roles, 'roles');

  const { id, roles: currentRoles } = user;
  const updatedRoles = currentRoles.filter((role) => !roles.includes(role));

  const response = await soulApiRequest({
    url: `/user/${encodeURIComponent(id)}`,
    method: 'patch',
    data: {
      roles: updatedRoles
    }
  });

  return response.data;
};

export default deAssignRolesFromUserById;
