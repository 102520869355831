import Assert from '@/helpers/assert';

export default class OrganizationProp {
  constructor(id, name, displayName, roleDefinitions, appUrls) {
    Assert.isPresent(id, 'id');
    Assert.isPresent(name, 'name');
    Assert.isPresent(displayName, 'displayName');

    this.id = id;
    this.name = name;
    this.displayName = displayName;
    if (roleDefinitions !== undefined) {
      this.roleDefinitions = roleDefinitions;
    }
    if (appUrls !== undefined) {
      this.appUrls = appUrls;
    }
    if (!OrganizationProp.validateProps(this)) {
      console.warn(
        'value must be provided with correct data type for id, name, displayName and roleDefinitions!'
      );
    }
  }

  static validateProps(props) {
    if (!props) {
      return false;
    }
    if (typeof props !== 'object') {
      console.warn('value must be an object!');
      return false;
    }
    if ('id' in props && typeof props.id !== 'string') {
      console.warn('id must be a string!');
      return false;
    }
    if ('name' in props && typeof props.name !== 'string') {
      console.warn('name must be a string!');
      return false;
    }
    if ('displayName' in props && typeof props.displayName !== 'string') {
      console.warn('displayName must be a string!');
      return false;
    }
    if ('roleDefinitions' in props && !Array.isArray(props.roleDefinitions)) {
      console.warn('roleDefinitions must be an array!');
      return false;
    }
    if ('appUrls' in props && !Array.isArray(props.appUrls)) {
      console.warn('roleDefinitions must be an array!');
      return false;
    }
    return true;
  }
}
