import ProductName from '@/models/ProductName';
import { BadRequest } from '@/helpers/api-errors';

const getRbaclRequestUrl = (productName) => {
  if (productName === ProductName.PolicyConnect) {
    return '/rbacl/policy-connect';
  }
  if (productName === ProductName.SafeTripBuilder) {
    return '/rbacl/safe-trip-builder';
  }
  throw new BadRequest(`Product name ${productName} is not handled.`);
};

export default getRbaclRequestUrl;
