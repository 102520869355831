const Pagination = {
  extractMetadata: (dataInfo, page, itemsPerPage) => {
    const remainingCount = dataInfo.total - dataInfo.start;

    const pageStop =
      remainingCount < itemsPerPage ? dataInfo.total : dataInfo.start + dataInfo.limit;

    return {
      page,
      pageStart: dataInfo.start,
      pageStop,
      itemsPerPage,
      itemsLength: dataInfo.total
    };
  }
};
export default Pagination;
