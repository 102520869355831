import Assert from '@/helpers/assert';

const Identity = (connection, isSocial, provider, userId) => {
  return {
    connection: Assert.isPresent(connection, 'identity.connection'),
    isSocial: Assert.isPresent(isSocial, 'identity.isSocial'),
    provider: Assert.isPresent(provider, 'identity.provider'),
    userId: Assert.isPresent(userId, 'identity.userId')
  };
};

export default Identity;
