<template>
  <cs-form-dialog
    :id="id"
    :ref="id"
    class="user-roles-select-checkbox-dialog"
    :heading="heading"
    :model-value="showDialog"
    :primary-action="{
      label: primaryLabel,
      disabled: !canConfirmSelection
    }"
    :secondary-action1="{
      label: secondaryLabel
    }"
    @primary-click="onConfirm"
    @secondary1-click="onCancel"
    @update:model-value="onCancel"
  >
    <template #cs-form-dialog-content>
      <user-roles-select-checkbox
        id="roles-list"
        v-model="selectedRoles"
        group-id="roles-list-group"
        header="Roles"
        :hint="checkboxHint"
        multiple
      />
    </template>
  </cs-form-dialog>
</template>
<script>
import { CSBase, CSFormDialog } from '@complispace/cs-design-system';
import UserRolesSelectCheckbox from '@/components/UserRolesSelectCheckbox';

export default {
  name: 'UserRolesSelectCheckboxDialog',

  components: {
    'cs-form-dialog': CSFormDialog,
    'user-roles-select-checkbox': UserRolesSelectCheckbox
  },
  extends: CSBase,

  props: {
    heading: {
      type: String,
      required: true
    },

    modelValue: {
      type: Boolean,
      required: true
    },

    primaryLabel: { type: String, required: false, default: '' },
    secondaryLabel: { type: String, required: false, default: '' },
    checkboxHint: { type: String, required: false, default: '' }
  },

  emits: ['edit-roles', 'cancel-roles', 'update:modelValue'],

  data() {
    return {
      id: this.$attrs.id,
      showDialog: this.modelValue,
      selectedRoles: []
    };
  },

  computed: {
    canConfirmSelection() {
      return this.selectedRoles.length > 0;
    }
  },

  watch: {
    modelValue(val) {
      this.showDialog = val;
    }
  },

  methods: {
    onConfirm() {
      this.$emit('edit-roles', this.selectedRoles);
      this.selectedRoles = [];
      this.closeDialog();
    },

    onCancel() {
      this.$emit('cancel-roles');
      this.selectedRoles = [];
      this.closeDialog();
    },

    closeDialog() {
      this.showDialog = false;
      this.$emit('update:modelValue', false);
    }
  }
};
</script>
