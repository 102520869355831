import * as MutationTypes from '@/store/mutationTypes';

const mutations = {
  [MutationTypes.SET_ORGANIZATIONS](state, { organizations, itemsLength }) {
    state.organizations = organizations;
    state.itemsLength = itemsLength;
  }
};

export default mutations;
