export function getRequestSummary(error) {
  const { status } = error.response || {};

  const { baseURL = '', url = '', method = '' } = error.request || {};
  const apiUrl = baseURL ? new URL(url, baseURL).toString() : url;

  return [method.toUpperCase(), status, apiUrl].filter((x) => !!x).join(' - ');
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export function parseQueryParams(params) {
  if (params instanceof URLSearchParams) {
    const entries = Array.from(params.entries());
    return Object.fromEntries(entries);
  }

  return params;
}

export function getRequestDetails(error) {
  const { headers, data, params } = error.request || {};

  const requestDetails = {};

  if (headers) {
    requestDetails.headers = headers;
  }

  if (data) {
    requestDetails.data = data;
  }

  if (params) {
    requestDetails.params = parseQueryParams(params);
  }

  return requestDetails;
}

export function getResponseDetails(error) {
  const { headers, data } = error.response || {};

  const responseDetails = {};
  if (headers) {
    responseDetails.headers = headers;
  }

  if (data) {
    responseDetails.data = data;
  }

  return responseDetails;
}

export default function logApiError(error) {
  const appEnv = process.env.VUE_APP_APP_ENV;
  const isLocalDev = appEnv === 'development';

  const { isAxiosError, message } = error;

  if (!isAxiosError) {
    console.error(message);
    return undefined;
  }

  const summary = getRequestSummary(error);

  if (!isLocalDev) {
    console.error(`API Request Failed: ${summary} ${message}`);
    return undefined;
  }

  const request = getRequestDetails(error);
  const response = getResponseDetails(error);

  const ctx = {
    message: `API Request Failed: ${summary} ${message}`
  };

  if (!isEmptyObject(request)) {
    ctx.request = request;
  }

  if (!isEmptyObject(response)) {
    ctx.response = response;
  }

  console.error(ctx);
  console.error(error.stack);

  return undefined;
}
