import * as services from '@/dependency-injection';
import { UploadedUser, UploadedUsers } from '@/models';
import * as MutationTypes from '@/store/mutationTypes';

const actions = {
  fetchUploadedUsersFromLocalStorage({ commit }, connectionName) {
    const uploadedUsers = services.uploadedUsers.getUploadedUsersByConnectionName(connectionName);

    if ((uploadedUsers || []).length === 0) {
      commit(MutationTypes.SET_UPLOADED_USERS, { uploadedUsers: UploadedUsers.create() });
      return;
    }

    const report = {
      createdUploadedUsers: UploadedUsers.create(
        services.uploadedUsers
          .getCreatedUploadedUsersByConnectionName(connectionName)
          .map((it) => UploadedUser(it.name, it.surname, connectionName, it.email, it.roles))
      ),
      failedUploadedUsers: UploadedUsers.create(
        services.uploadedUsers
          .getFailedUploadedUsersByConnectionName(connectionName)
          .map((it) => UploadedUser(it.name, it.surname, connectionName, it.email, it.roles))
      ),
      skippedUploadedUsers: UploadedUsers.create(
        services.uploadedUsers
          .getSkippedUploadedUsersByConnectionName(connectionName)
          .map((it) => UploadedUser(it.name, it.surname, connectionName, it.email, it.roles))
      )
    };

    const processedUploadedUsers = UploadedUsers.create(report.createdUploadedUsers).concatenate(
      report.failedUploadedUsers
    );
    const unprocessedUploadedUsers = UploadedUsers.create(
      uploadedUsers.map((it) =>
        UploadedUser(it.name, it.surname, connectionName, it.email, it.roles)
      )
    ).excludeByEmail(processedUploadedUsers);

    commit(MutationTypes.SET_UPLOADED_USERS, { uploadedUsers: unprocessedUploadedUsers });
    commit(MutationTypes.SET_CREATED_UPLOADED_USERS, report.createdUploadedUsers);
    commit(MutationTypes.SET_FAILED_UPLOADED_USERS, report.failedUploadedUsers);
    commit(MutationTypes.SET_SKIPPED_UPLOADED_USERS, report.skippedUploadedUsers);
  },

  async initUploadedUsers({ commit, rootState }, file) {
    const { name: connectionName } = rootState.organization.selectedConnection;
    const { roleDefinitions } = rootState.organization.organization;

    const userRows = await services.parseUsers(file);

    const users = await services.soul.getAllUsersByConnectionName(connectionName);
    const existingUsers = UploadedUsers.create(
      users.map((it) => UploadedUser(it.name, it.surname, connectionName, it.email, it.roles))
    );

    userRows.every((row) => services.validateUser(roleDefinitions, connectionName)(row));

    const allUploadedUsers = UploadedUsers.create(
      userRows.map((obj) =>
        UploadedUser(obj.Name, obj.Surname, obj.ConnectionName, obj.Email, obj.Roles)
      )
    );

    const uploadedUsers = allUploadedUsers.uniqByEmail();

    const duplicatedUploadedUsers = UploadedUsers.create(
      uploadedUsers.filter((it) => {
        return existingUsers.getUserByEmail(it.email) != null;
      })
    );

    const newUploadedUsers = uploadedUsers.excludeByEmail(existingUsers);

    services.uploadedUsers.saveUploadedUsersByConnectionName(
      connectionName,
      newUploadedUsers.serialize()
    );

    commit(MutationTypes.SET_UPLOADED_USERS, {
      uploadedUsers: newUploadedUsers,
      duplicatedUploadedUsers
    });
  },

  resetUploadedUsers({ commit, rootState }) {
    const { name: connectionName } = rootState.organization.selectedConnection;

    services.uploadedUsers.removeUploadedUsersByConnectionName(connectionName);
    services.uploadedUsers.removeCreatedUploadedUsersByConnectionName(connectionName);
    services.uploadedUsers.removeFailedUploadedUsersByConnectionName(connectionName);
    services.uploadedUsers.removeSkippedUploadedUsersByConnectionName(connectionName);

    commit(MutationTypes.RESET_UPLOADED_USERS);
  },

  async refreshUploadedUsers({ state, commit }, connectionName) {
    const { uploadedUsers, createdUploadedUsers, failedUploadedUsers } = state;
    let existingUsers = [];

    try {
      const users = await services.soul.getAllUsersByConnectionName(connectionName);
      existingUsers = UploadedUsers.create(
        users.map((it) => UploadedUser(it.name, it.surname, connectionName, it.email, it.roles))
      );
    } catch (error) {
      console.error(error);
    }

    const updatedUploadedUsers = uploadedUsers.excludeByEmail(
      createdUploadedUsers.concatenate(failedUploadedUsers).concatenate(existingUsers)
    );

    commit(MutationTypes.SET_UPLOADED_USERS, {
      uploadedUsers: updatedUploadedUsers
    });
  },

  setUploadedUsers({ commit, state }, { connectionName, uploadedUsers: uploadedUsersView }) {
    const uploadedUsers = UploadedUsers.create(
      uploadedUsersView.map((it) =>
        UploadedUser(it.name, it.surname, connectionName, it.email, it.roles)
      )
    );

    if (uploadedUsers.isEqual(state.uploadedUsers)) {
      return;
    }

    services.uploadedUsers.saveUploadedUsersByConnectionName(
      connectionName,
      uploadedUsers.serialize()
    );

    commit(MutationTypes.SET_UPLOADED_USERS, {
      uploadedUsers
    });
  },

  setCreatedUploadedUsers(
    { commit, state },
    { createdUploadedUsers: createdUploadedUsersView, connectionName }
  ) {
    const createdUploadedUsers = UploadedUsers.create(
      createdUploadedUsersView.map((it) =>
        UploadedUser(it.name, it.surname, connectionName, it.email, it.roles)
      )
    );

    if (createdUploadedUsers.isEqual(state.createdUploadedUsers)) {
      return;
    }

    services.uploadedUsers.saveCreatedUploadedUsersByConnectionName(
      connectionName,
      createdUploadedUsers.serialize()
    );

    commit(MutationTypes.SET_CREATED_UPLOADED_USERS, createdUploadedUsers);
  },

  setFailedUploadedUsers(
    { commit, state },
    { failedUploadedUsers: failedUploadedUsersView, connectionName }
  ) {
    const failedUploadedUsers = UploadedUsers.create(
      failedUploadedUsersView.map((it) =>
        UploadedUser(it.name, it.surname, connectionName, it.email, it.roles)
      )
    );

    if (failedUploadedUsers.isEqual(state.failedUploadedUsers)) {
      return;
    }

    services.uploadedUsers.saveFailedUploadedUsersByConnectionName(
      connectionName,
      failedUploadedUsers.serialize()
    );

    commit(MutationTypes.SET_FAILED_UPLOADED_USERS, failedUploadedUsers);
  },

  setSkippedUploadedUsers(
    { commit, state },
    { skippedUploadedUsers: skippedUploadedUsersView, connectionName }
  ) {
    const skippedUploadedUsers = UploadedUsers.create(
      skippedUploadedUsersView.map((it) =>
        UploadedUser(it.name, it.surname, connectionName, it.email, it.roles)
      )
    );

    if (skippedUploadedUsers.isEqual(state.skippedUploadedUsers)) {
      return;
    }

    services.uploadedUsers.saveSkippedUploadedUsersByConnectionName(
      connectionName,
      skippedUploadedUsers.serialize()
    );

    commit(MutationTypes.SET_SKIPPED_UPLOADED_USERS, skippedUploadedUsers);
  },

  removeSkippedUploadedUser({ commit, state }, { skippedUploadedUser, connectionName }) {
    const updated = state.skippedUploadedUsers.removeUser(skippedUploadedUser);
    commit(MutationTypes.SET_SKIPPED_UPLOADED_USERS, updated);

    services.uploadedUsers.saveSkippedUploadedUsersByConnectionName(
      connectionName,
      updated.serialize()
    );
  },

  appendSkippedUploadedUser({ commit, state }, { skippedUploadedUser, connectionName }) {
    const updated = state.skippedUploadedUsers.concatenate(skippedUploadedUser);

    commit(MutationTypes.SET_SKIPPED_UPLOADED_USERS, updated);

    services.uploadedUsers.saveSkippedUploadedUsersByConnectionName(
      connectionName,
      updated.serialize()
    );
  }
};

export default actions;
