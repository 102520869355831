export default function componentErrorHandler(comp, error, message, isDialog) {
  const isLocalEnv = process.env.VUE_APP_APP_ENV === 'development';
  const isValidComponent = comp && comp.showErrorAlert;
  const hasMessage = message || error instanceof Error;

  if (!isValidComponent || !hasMessage) {
    return;
  }

  if (comp.clearLoading) {
    comp.clearLoading();
  }

  if (error instanceof Error && isLocalEnv) {
    console.error(`${error.name}: ${error.message}`);
    console.error(error.stack);
  }

  if (message) {
    comp.showErrorAlert(message, isDialog);

    return;
  }

  let msg;

  switch (error.name) {
    case 'AssertError': {
      msg =
        'The request could not be made due to missing or incorrect data. Please contact complispace.';
      break;
    }

    case 'ApiError': {
      msg = 'An unexpected error occured on the server. Please contact complispace.';
      break;
    }

    case 'BadRequest': {
      msg = 'The request sent to the server was invalid. Please contact complispace.';
      break;
    }

    case 'Forbidden': {
      msg =
        'You are not permitted to access this resource. Please contact the admin or complispace.';
      break;
    }

    case 'NetworkError': {
      msg = 'The server could not be reached. Please contact the admin or complispace.';
      break;
    }

    case 'NotFound': {
      msg = 'The resource you requested could not be found.';
      break;
    }

    case 'ServerError': {
      msg = 'Internal server error/unhandled error. Please contact complispace.';
      break;
    }

    default: {
      msg = 'Something unexpected happened. Please contact complispace.';
      break;
    }
  }

  comp.showErrorAlert(msg, isDialog);
}
