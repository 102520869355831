const Users = {
  create: (userList) => {
    const users = (userList || []).slice(0);

    users.findById = (id) => users.find((it) => id === it.id) || null;

    users.concatenate = (other) => {
      return Users.create(users.concat(other));
    };

    users.filterByRole = (role) => {
      const filtered = users.filter((it) => it.hasRole(role));
      return Users.create(filtered);
    };

    users.updateUser = (user) => {
      const updated = users.map((it) => (it.id === user.id ? user : it));
      return Users.create(updated);
    };

    return users;
  }
};

export default Users;
