<template>
  <cs-view single-view>
    <template #content>
      <div class="mt-4 ml-4">
        <v-alert
          v-if="connections.length === 0 && message"
          id="alert-no-connections"
          prominent
          type="error"
          class="rounded-shaped"
          >{{ message }}</v-alert
        >
      </div>
    </template>
  </cs-view>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters, mapState } from 'vuex';
import RouteName from '@/router/routeName';

export default {
  name: 'HomePage',

  extends: CSBase,

  data() {
    return {
      message: ''
    };
  },

  computed: {
    ...mapGetters({
      isCompliSpaceStaff: 'authorization/isCompliSpaceStaff',
      isClientAdmin: 'authorization/isClientAdmin'
    }),
    ...mapState({
      connections: (state) => state.organization.connections,
      selectedConnection: (state) => state.organization.selectedConnection
    })
  },

  async mounted() {
    if (!this.isCompliSpaceStaff && !this.isClientAdmin) {
      this.$store.dispatch('authorization/logout');
      localStorage.setItem(
        'errorAlertMessage',
        "You don't have enough permission as Complispace Staff or Client Administrator to access this App."
      );
    }

    // if there are no connections, fetch the connections
    if (this.connections.length === 0) {
      try {
        await this.$store.dispatch('organization/fetchConnections');
      } catch (e) {
        console.error(e.message);
      }
    }

    // if there are no connections after we fetch them, add a message to be shown.
    if (this.connections.length === 0) {
      await this.$router.push({
        name: RouteName.ROLE_DEFINITIONS
      });
      return;
    }

    const firstConnection = this.connections[0].name;
    await this.$router.push({
      name: 'Connection',
      params: {
        connectionName: firstConnection
      },
      query: {
        itemsPerPage: 10,
        page: 1
      }
    });
  }
};
</script>

<style scoped>
.code {
  text-align: left;
}

.token {
  text-align: left;
}
</style>
