<template>
  <div class="uploaded-users-csv">
    <v-card>
      <v-card-title class="uploaded-users-card-title">
        <div class="title-1">Uploaded Users</div>
        <v-spacer></v-spacer>

        <cs-button
          id="upload-users-report-btn"
          class="ml-2"
          :disabled="!downloadableReport"
          :primary="isReportPrimaryAction ? true : false"
          label="Report"
          @click="showReport"
        >
        </cs-button>

        <csv-upload id="csv-upload"></csv-upload>

        <create-users
          id="create-users"
          :connection-name="connectionName"
          :disabled="!isSelected"
          :primary="isSelected"
          :selected="selectedUsers"
          class="ml-2"
          @create-users-completed="onCreateUsersCompleted"
        ></create-users>
      </v-card-title>

      <v-card-subtitle>
        <v-text-field
          id="search-uploaded-user-text-field"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          max-width="250px"
          color="primary"
          variant="underlined"
        ></v-text-field>
      </v-card-subtitle>
      <v-data-table
        id="uploaded-users-table"
        :headers="uploadedUsersView.headers"
        :items="uploadedUsersView.items"
        :search="search"
        show-select
        return-object
        select-strategy="all"
        :model-value="selectedUsers"
        @update:model-value="onSelectedUsersChange"
      >
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template #item.roles="{ item }">
          {{ concatRoles(item) }}
        </template>
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template #item.data-table-select="{ item }">
          <v-checkbox
            :model-value="selectedUsers"
            :value="item"
            color="primary"
            multiple
            hide-details
            @update:model-value="onSelectedUsersChange"
          />
        </template>
        <template #no-data>
          <span v-if="loading" id="uploaded-users-loading-text">Loading file...</span>
          <span v-else id="uploaded-users-no-data-text">No data available</span>
        </template>
      </v-data-table>
    </v-card>

    <report-dialog
      id="uploaded-users-report-dialog"
      v-model="showReportDialog"
      table-id="uploaded-users-report-table"
      heading="Uploaded Users Report"
      :href="downloadableReport"
      :disabled="!downloadableReport"
      :show-search="false"
      :headers="uploadedUsersReportView.headers"
      :users="uploadedUsersReportView.items"
      :file-name-prefix="reportFileNamePrefix"
    ></report-dialog>
  </div>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';
import compact from 'lodash/compact';
import { mapActions, mapGetters, mapState } from 'vuex';

import { UploadedUser, UploadedUsers } from '@/models';

import CreateUsers from '@/components/bulkActions/CreateUsers';
import ReportDialog from '@/components/ReportDialog';
import CsvUpload from '@/components/CsvUpload';

export default {
  name: 'UploadUsersCSV',
  components: {
    'create-users': CreateUsers,
    'report-dialog': ReportDialog,
    'csv-upload': CsvUpload
  },
  extends: CSBase,

  async beforeRouteUpdate(to, _, next) {
    const { connectionName } = to.params;

    if (this.isValidConnectionName(connectionName)) {
      await this.fetchUploadedUsers(connectionName);
      next();
    }
  },

  props: {
    connectionName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      search: '',
      selectedUsers: [],
      showReportDialog: false
    };
  },

  computed: {
    ...mapGetters({
      appUrls: 'organization/appUrlsView',
      uploadedUsersView: 'uploadedUsers/uploadedUsersView',
      uploadedUsersReportView: 'uploadedUsers/uploadedUsersReportView',
      downloadableReport: 'uploadedUsers/uploadedUsersDownloadableReport'
    }),

    ...mapState({
      organization: (state) => state.organization.organization,
      skippedUsers: (state) => state.uploadedUsers.skippedUploadedUsers,
      connectionNames: (state) => (state.organization.connections || []).map((it) => it.name),
      createdUsers: (state) => state.uploadedUsers.createdUploadedUsers,
      failedUsers: (state) => state.uploadedUsers.failedUploadedUsers
    }),

    isSelected() {
      return this.selectedUsers.length > 0;
    },

    hasUploadedUsers() {
      return this.$store.state.uploadedUsers.length > 0;
    },

    hasCreatedOrFailedUsers() {
      return this.createdUsers.length > 0 || this.failedUsers.length > 0;
    },

    isReportPrimaryAction() {
      return this.hasCreatedOrFailedUsers && !this.hasUploadedUsers;
    },

    reportFileNamePrefix() {
      return compact([
        'uploaded-users-report',
        this.organization?.name || '',
        this.connectionName
      ]).join('-');
    }
  },

  watch: {
    uploadedUsersView(newValue) {
      if (!(newValue && Array.isArray(newValue.items))) {
        this.selectedUsers = [];
        return;
      }

      if (this.skippedUsers.length) {
        const skippedUserEmails = this.skippedUsers.map((it) => it.email);
        this.selectedUsers = newValue.items.filter((it) => !skippedUserEmails.includes(it.email));
      } else {
        this.selectedUsers = newValue.items;
      }
    },

    connectionName() {
      this.resetConnectionData();
    }
  },

  async mounted() {
    await this.resetConnectionData();
  },

  methods: {
    ...mapActions({
      fetchUploadedUsers: 'uploadedUsers/fetchUploadedUsersFromLocalStorage',
      refreshUploadedUsers: 'uploadedUsers/refreshUploadedUsers',
      setSkippedUsers: 'uploadedUsers/setSkippedUploadedUsers'
    }),

    async resetConnectionData() {
      await this.fetchUploadedUsers(this.connectionName);
    },

    isValidConnectionName(connectionName) {
      if (!this.connectionNames.length) {
        return false;
      }

      return this.connectionNames.includes(connectionName);
    },

    onCreateUsersCompleted() {
      this.refreshUploadedUsers(this.connectionName);
    },

    onSelectedUsersChange(selected) {
      this.selectedUsers = selected;
      const notSelectedUsers = this.uploadedUsersView.items.filter(
        (user) => !selected.some((selectedUser) => selectedUser.id === user.id)
      );
      const skippedUploadedUsers = this.getSkippedUploadedUsers(notSelectedUsers);
      this.setSkippedUsers({
        connectionName: this.connectionName,
        skippedUploadedUsers
      });
    },

    getSkippedUploadedUsers(notSelectedUsers) {
      return UploadedUsers.create(
        notSelectedUsers.map((user) => {
          return UploadedUser(user.name, user.surname, user.connectionName, user.email, user.roles);
        })
      );
    },

    showReport() {
      this.showReportDialog = true;
    },

    uploadUserCsvRowClassName() {
      return 'uploaded-user-csv-row';
    },

    concatRoles(item) {
      return item.roles.join(', ');
    }
  }
};
</script>

<style scoped>
.v-alert__content {
  word-wrap: break-word !important;
  overflow: scroll !important;
}

.uploaded-users-card-title {
  display: flex;
  justify-content: flex-end;
}

#uploaded-users-report-table >>> table {
  table-layout: fixed;
}

#uploaded-users-report-table >>> table >>> thead.v-data-table-header >>> th {
  white-space: normal;
}

@media screen and (max-width: 1264px) {
  .uploaded-users-card-title {
    flex-direction: column;
    gap: 12px;
  }
}
</style>
