import { UploadedUsers } from '@/models';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  uploadedUsers: UploadedUsers.create(),
  createdUploadedUsers: UploadedUsers.create(),
  failedUploadedUsers: UploadedUsers.create(),
  skippedUploadedUsers: UploadedUsers.create(),
  duplicatedUploadedUsers: UploadedUsers.create()
};

const store = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
};

export default store;
