<template>
  <cs-view single-view>
    <template #content>
      <div class="application-urls mt-3 mx-1">
        <app-urls-editor id="app-urls-editor"></app-urls-editor>
      </div>
    </template>
  </cs-view>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';
import AppUrlsEditor from '@/components/AppUrlsEditor';

export default {
  name: 'ApplicationUrls',

  components: {
    'app-urls-editor': AppUrlsEditor
  },

  extends: CSBase,

  data() {
    return {};
  }
};
</script>
