import Assert from '@/helpers/assert';
import getRbaclRequestUrl from '@/helpers/getRbaclRequestUrl';

const getRbaclByProductNameAndAppUrl = (soulApiRequest) => async (productName, appUrl) => {
  Assert.isPresent(productName, 'productName');
  Assert.isPresent(appUrl, 'appUrl');

  const url = getRbaclRequestUrl(productName);

  const response = await soulApiRequest({
    url,
    method: 'get',
    params: new URLSearchParams({ appUri: appUrl })
  });

  return response.data.rbacl;
};

export default getRbaclByProductNameAndAppUrl;
