<template>
  <cs-form-dialog
    :id="id"
    ref="report-dialog"
    :model-value="showDialog"
    :heading="heading"
    class="report-dialog"
    :primary-action="{
      disabled,
      label: 'Download Report'
    }"
    @update:model-value="onCloseDialog"
    @primary-click="onDownloadReport"
  >
    <template #cs-form-dialog-content>
      <confirm-users-table
        :id="tableId"
        class="report-table"
        :users="users"
        :headers="headers"
        :show-search="showSearch"
      ></confirm-users-table>
    </template>
  </cs-form-dialog>
</template>

<script>
import DateTime from '@/helpers/DateTime';
import { CSBase } from '@complispace/cs-design-system';
import ConfirmUsersTable from './ConfirmUsersTable';

export default {
  name: 'ReportDialog',

  components: {
    'confirm-users-table': ConfirmUsersTable
  },
  extends: CSBase,

  props: {
    tableId: { type: String, required: false, default: '' },
    href: { type: String, required: false, default: '' },

    showSearch: {
      type: Boolean,
      default() {
        return true;
      }
    },

    modelValue: {
      type: Boolean,
      required: true
    },

    heading: {
      type: String,
      required: true
    },

    headers: {
      type: Array,
      required: true
    },

    users: {
      type: Array,
      required: true
    },

    disabled: {
      type: Boolean,

      default() {
        return false;
      }
    },

    fileNamePrefix: {
      type: String,
      required: true
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      id: this.$attrs.id,
      showDialog: this.modelValue
    };
  },

  watch: {
    modelValue(val) {
      this.showDialog = val;
    }
  },

  methods: {
    getReportFileName() {
      const timestamp = DateTime().stringifyAsTimestamp('Australia/Sydney');
      return `${this.fileNamePrefix}-${timestamp}`;
    },

    onCloseDialog() {
      this.$emit('update:modelValue', false);
    },

    createLink(href, fileName) {
      const link = document.createElement('a');

      link.href = href;
      link.download = fileName;

      return link;
    },

    downloadFile(link) {
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    onDownloadReport() {
      const fileName = this.getReportFileName();

      const link = this.createLink(this.href, fileName);
      this.downloadFile(link);
    }
  }
};
</script>

<style scoped>
.report-table >>> table {
  table-layout: fixed;
}

.report-table >>> table >>> thead.v-data-table-header >>> th {
  white-space: normal;
}
</style>
