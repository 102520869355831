import Users from '@/models/Users';
import Pagination from '@/helpers/Pagination';
import Assert from '../../helpers/assert';
import User from '../../models/User';

export const getUsersFromResponse = (response) => {
  return {
    users: Assert.isArray(response.data.users, 'response.data.users')
  };
};

const getUsersByConnectionName =
  (soulApiRequest) => async (connectionName, itemsPerPage, page, sortBy, order, search) => {
    Assert.isPresent(connectionName, 'connectionName');
    Assert.isPresent(connectionName, 'itemsPerPage');
    Assert.isPresent(page, 'page');

    if (page === 0) {
      throw new Error('failed to get user by connection because page needs to be start from 1');
    }

    const queryParams = {
      filter_connection: connectionName,
      per_page: itemsPerPage
    };

    if (page >= 1) {
      queryParams.page = page - 1;
    }

    if (sortBy) {
      queryParams.sortBy = sortBy.concat(':', order);
    }

    if (search) {
      let searchText = `${search}*`;
      if (String(search).length >= 3) {
        searchText = `*${searchText}`;
      }

      queryParams.orQuery_name = searchText;
      queryParams.orQuery_surname = searchText;
      queryParams.orQuery_email = searchText;
    }

    const params = new URLSearchParams(queryParams);

    const response = await soulApiRequest({
      url: '/users',
      method: 'get',
      params
    });

    const { users } = getUsersFromResponse(response);

    const userList = users.map((obj) => {
      return User(
        obj.appMetadata,
        obj.blocked,
        obj.email,
        obj.emailVerified,
        obj.groups,
        obj.id,
        obj.identities,
        obj.loginsCount,
        obj.name,
        obj.picture,
        obj.roles,
        obj.surname,
        obj.userMetadata
      );
    });

    return {
      users: Users.create(userList),
      metadata: Pagination.extractMetadata(response.data, page, itemsPerPage)
    };
  };

export default getUsersByConnectionName;
