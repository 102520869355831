import moment from 'moment-timezone';
import Assert from './assert';

const dateTime = (raw) => {
  const rawDate = raw || new Date();
  const date = moment(rawDate);

  date.copy = () => {
    return dateTime(date);
  };

  date.stringify = (timezone, format) => {
    return date.tz(timezone).format(format);
  };

  date.stringifyAsTimestamp = (timezone) => {
    Assert.isPresent(timezone, 'timezone');
    const format = 'YYYY-MMDD-hhmmss';
    return date.stringify(timezone, format);
  };

  return date;
};

export default dateTime;
