import Assert from '@/helpers/assert';
import difference from 'lodash/difference';
import mapKeys from 'lodash/mapKeys';

export default (roleDefs, connectionName) => (row) => {
  const obj = mapKeys(row, (...args) => {
    const key = args[1].toLowerCase();
    return key;
  });

  Assert.isPresent(obj.name, 'name');
  Assert.isPresent(obj.surname, 'surname');
  Assert.isPresent(obj.connectionname, 'connectionName');
  Assert.isPresent(obj.email, 'email');
  Assert.isPresent(obj.roles, 'roles');

  if (obj.connectionname !== connectionName) {
    throw new Error('connection name is invalid.');
  }

  const roleDefsWithUserRoles = Array.from(new Set(roleDefs.concat(obj.roles)));
  const onlyRoleDefs = Array.from(new Set(roleDefs));
  const diff = difference(roleDefsWithUserRoles, onlyRoleDefs);

  if (diff.length > 0) {
    throw new Error('some roles are invalid.');
  }

  if (!/.+@.+\..+/.test(obj.email)) {
    throw new Error('email does not have "@".');
  }

  return true;
};
