export default class CsvParserError extends Error {
  constructor(message, parserCode, parserMessage, fileMetadata) {
    super(message);

    this.name = 'CsvParserError';

    this.parserCode = parserCode;
    this.parserMessage = parserMessage;
    this.fileMetadata = fileMetadata; // { name, size, type }
  }
}
