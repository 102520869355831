import compact from 'lodash/compact';
import Assert from '@/helpers/assert';
import { Organization } from '@/models';
import AppUrl from '@/models/AppUrl';

const getOrganizationById = (soulApiRequest) => async (id) => {
  Assert.isPresent(id, 'id');

  const response = await soulApiRequest({
    url: `/organization/${encodeURIComponent(id)}`,
    method: 'get'
  });

  const { data } = response;

  const appUrls = data.organization.appUrls.map((appUrl) => {
    if (appUrl.policyConnect) {
      return AppUrl('policyConnect', appUrl.policyConnect);
    }

    if (appUrl.policyPlus) {
      return AppUrl('policyPlus', appUrl.policyPlus);
    }

    if (appUrl.assurance) {
      return AppUrl('assurance', appUrl.assurance);
    }

    if (appUrl.planCheckGo) {
      return AppUrl('planCheckGo', appUrl.planCheckGo);
    }

    if (appUrl.safeTripBuilder) {
      return AppUrl('safeTripBuilder', appUrl.safeTripBuilder);
    }

    if (appUrl.compliLearnPortal) {
      return AppUrl('compliLearnPortal', appUrl.compliLearnPortal);
    }

    if (appUrl.go1) {
      return AppUrl('go1', appUrl.go1);
    }

    return null;
  });

  return Organization(
    data.organization.id,
    data.organization.name,
    data.organization.displayName,
    data.organization.roleDefinitions,
    compact(appUrls)
  );
};

export default getOrganizationById;
