const Organizations = {
  create: (organizationList) => {
    const organizations = (organizationList || []).slice(0);

    organizations.concatenate = (other) => {
      return Organizations.create(organizations.concat(other));
    };
    return organizations;
  }
};

export default Organizations;
