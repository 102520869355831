import { Organizations } from '@/models';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
  organizations: Organizations.create(),
  itemsLength: 0
};

const store = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default store;
