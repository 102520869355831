<template>
  <cs-view single-view>
    <template #content>
      <div class="role-definitions mt-3 mx-1">
        <role-definitions-editor id="role-definitions-editor" :organization="organization" />
      </div>
    </template>
  </cs-view>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapState } from 'vuex';
import RoleDefinitionsEditor from '@/components/RoleDefinitionsEditor';
import OrganizationProp from '@/props/OrganizationProp';

export default {
  name: 'RoleDefinitions',

  components: { 'role-definitions-editor': RoleDefinitionsEditor },

  extends: CSBase,

  computed: {
    ...mapState({
      organization: (state) => {
        const org = state.organization.organization;
        if (org) {
          return new OrganizationProp(
            org.id,
            org.name,
            org.displayName,
            org.roleDefinitions,
            org.appUrls
          );
        }
        return undefined;
      }
    })
  }
};
</script>
