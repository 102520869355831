import range from 'lodash/range';
import Assert from '../../helpers/assert';
import Users from '../../models/Users';
import getUsersByConnectionName from './getUsersByConnectionName';

const getAllUsersByConnectionName = (soulApiRequest) => async (connectionName, sortBy, order) => {
  Assert.isPresent(connectionName, 'connectionName');

  const itemsPerPage = 50;

  let allUsers = Users.create();

  const { users: firstPage, metadata } = await getUsersByConnectionName(soulApiRequest)(
    connectionName,
    itemsPerPage,
    1,
    sortBy,
    order
  );

  allUsers = allUsers.concatenate(firstPage);

  const { itemsLength } = metadata;
  const totalPages = Math.ceil(itemsLength / itemsPerPage);

  if (totalPages > 1) {
    const pages = range(2, totalPages + 1);

    const promises = pages.map((page) =>
      getUsersByConnectionName(soulApiRequest)(connectionName, itemsPerPage, page, sortBy, order)
    );

    const responses = await Promise.all(promises);
    responses.forEach((response) => {
      allUsers = allUsers.concatenate(response.users);
    });
  }

  return allUsers;
};

export default getAllUsersByConnectionName;
