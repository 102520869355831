import Assert from '@/helpers/assert';

const saveUploadedUsersByKey = (localStorage, key) => (connectionName, uploadedUsers) => {
  Assert.isPresent(key, 'key');
  Assert.isPresent(connectionName, 'connectionName');
  Assert.isPresent(uploadedUsers, 'uploadedUsers');

  const str = localStorage.getItem(key);
  const uploadedUsersByConnection = str ? JSON.parse(str) : {};

  uploadedUsersByConnection[connectionName] = uploadedUsers;
  localStorage.setItem(key, JSON.stringify(uploadedUsersByConnection));
};

export default saveUploadedUsersByKey;
