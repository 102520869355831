import parseCsv from './parseCsv';
import readAsText from './readAsText';
import stringifyCsv from './stringifyCsv';

export default function csv(parser, reader, stringify) {
  return {
    parseCsv: parseCsv(parser, reader),
    readAsText,
    stringifyCsv: stringifyCsv(stringify)
  };
}
