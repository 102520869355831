import * as services from '@/dependency-injection';
import createDownloadableUrl from '@/helpers/createDownloadableUrl';

export const UPLOADED_USERS_STATUS_CREATED = 'Created';
export const UPLOADED_USERS_STATUS_FAILED = 'Failed';
export const UPLOADED_USERS_STATUS_SKIPPED = 'Skipped';

const getters = {
  uploadedUsersView(state) {
    const headers = [
      {
        title: 'Name',
        key: 'name'
      },
      {
        title: 'Surname',
        key: 'surname'
      },
      {
        title: 'Connection Name',
        key: 'connectionName'
      },
      {
        title: 'Email',
        key: 'email'
      },
      {
        title: 'Roles',
        key: 'roles'
      }
    ];

    const { uploadedUsers } = state;

    return {
      headers,
      items: uploadedUsers.map((user, index) => {
        const { name, surname, connectionName, email, roles } = user;
        return {
          id: `uploaded-user-${index}`,
          name,
          surname,
          connectionName,
          email,
          roles
        };
      })
    };
  },

  uploadedUsersReportView(state, _, rootState) {
    const headers = [
      {
        title: 'Name',
        key: 'name',
        width: '15%'
      },
      {
        title: 'Surname',
        key: 'surname',
        width: '15%'
      },
      {
        title: 'Connection Name',
        key: 'connectionName',
        width: '15%'
      },
      {
        title: 'Email',
        key: 'email',
        width: '20%'
      },
      {
        title: 'Roles',
        key: 'roles',
        width: '25%'
      },
      {
        title: 'Status',
        key: 'status',
        width: '12%'
      }
    ];

    if (
      rootState.organization &&
      rootState.organization.selectedConnection &&
      rootState.organization.selectedConnection.name
    ) {
      const connectionName = rootState.organization.selectedConnection.name;

      const className = 'uploaded-user-report-row';
      const { createdUploadedUsers, failedUploadedUsers } = state;
      const uploadedUsers = services.uploadedUsers.getUploadedUsersByConnectionName(connectionName);

      const items = uploadedUsers.map((user) => {
        let status = UPLOADED_USERS_STATUS_SKIPPED;
        const { name, surname, email, roles } = user.serialize();

        if (createdUploadedUsers.contains(user)) {
          status = UPLOADED_USERS_STATUS_CREATED;
        } else if (failedUploadedUsers.contains(user)) {
          status = UPLOADED_USERS_STATUS_FAILED;
        }

        return {
          className,
          name,
          surname,
          connectionName,
          email,
          roles: roles.join(', '),
          status
        };
      });

      return {
        headers,
        items
      };
    }

    return {
      headers,
      items: []
    };
  },

  uploadedUsersDownloadableReport(state, _, rootState) {
    const columns = [
      {
        key: 'name',
        header: 'Name'
      },
      {
        key: 'surname',
        header: 'Surname'
      },
      {
        key: 'email',
        header: 'Email'
      },
      {
        key: 'connectionName',
        header: 'ConnectionName'
      },
      {
        key: 'roles',
        header: 'Roles'
      },
      {
        key: 'status',
        header: 'Status'
      }
    ];

    const { createdUploadedUsers, failedUploadedUsers, skippedUploadedUsers } = state;

    if (createdUploadedUsers.length === 0 && failedUploadedUsers.length === 0) {
      return undefined;
    }

    if (
      rootState.organization &&
      rootState.organization.selectedConnection &&
      rootState.organization.selectedConnection.name
    ) {
      const connectionName = rootState.organization.selectedConnection.name;
      const uploadedUsers = services.uploadedUsers.getUploadedUsersByConnectionName(connectionName);

      if (uploadedUsers && uploadedUsers.length > 0) {
        const report = uploadedUsers.map((user) => {
          const { name, surname, email, roles } = user;
          let status = UPLOADED_USERS_STATUS_SKIPPED;

          if (createdUploadedUsers.contains(user)) {
            status = UPLOADED_USERS_STATUS_CREATED;
          }

          if (failedUploadedUsers.contains(user)) {
            status = UPLOADED_USERS_STATUS_FAILED;
          }

          if (skippedUploadedUsers.contains(user)) {
            status = UPLOADED_USERS_STATUS_SKIPPED;
          }

          return {
            name,
            surname,
            connectionName,
            email,
            roles: roles.join(';'),
            status
          };
        });

        const reportCsvStr = services.csv.stringifyCsv(report, columns);

        const href = createDownloadableUrl(reportCsvStr);

        return href;
      }
    }

    return undefined;
  }
};

export default getters;
