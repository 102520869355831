<template>
  <cs-form-dialog
    :id="id"
    ref="confirm-users-dialog"
    :model-value="showDialog"
    :heading="heading"
    :primary-action="{
      label: cancelLabel
    }"
    :secondary-action1="{
      label: confirmLabel
    }"
    @update:model-value="onCancel"
    @primary-click="onCancel"
    @secondary1-click="onConfirm"
  >
    <template #cs-form-dialog-content>
      <confirm-users-table
        id="confirm-users-dialog-card"
        ref="table"
        :search-id="searchId"
        :table-id="tableId"
        :headers="headers"
        :users="selectedUsersWithJoinedRoles"
      ></confirm-users-table>
    </template>
  </cs-form-dialog>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import ConfirmUsersTable from './ConfirmUsersTable';

export default {
  name: 'ConfirmUsersDialog',

  components: {
    'confirm-users-table': ConfirmUsersTable
  },
  extends: CSBase,

  props: {
    heading: {
      type: String,
      required: true
    },

    headers: {
      type: Array,
      required: true
    },

    confirmLabel: {
      type: String,
      default() {
        return 'Yes';
      }
    },

    cancelLabel: {
      type: String,
      default() {
        return 'No';
      }
    },

    selectedUsers: {
      type: Array,
      required: true
    },

    searchId: { type: String, required: false, default: '' },
    tableId: { type: String, required: false, default: '' },

    modelValue: {
      type: Boolean,
      required: true
    }
  },

  emits: ['confirm-users', 'confirm-users-cancel', 'update:modelValue'],

  data() {
    return {
      id: this.$attrs.id,
      showDialog: this.modelValue
    };
  },

  computed: {
    selectedUsersWithJoinedRoles() {
      return this.selectedUsers.map((user) => ({
        ...user,
        roles: user.roles.join(', ')
      }));
    }
  },

  watch: {
    modelValue(val) {
      this.showDialog = val;
    }
  },

  methods: {
    onConfirm() {
      this.$emit('confirm-users');
    },

    onCancel() {
      this.$emit('confirm-users-cancel');
      this.closeDialog();
    },

    resetSearch() {
      this.$refs.table.resetSearch();
    },

    closeDialog() {
      this.resetSearch();
      this.showDialog = false;
      this.$emit('update:modelValue', this.showDialog);
    }
  }
};
</script>
