const stringifyCsv = (stringify) => (report, columns) => {
  const options = {
    header: true,
    columns
  };

  return stringify(report, options);
};

export default stringifyCsv;
