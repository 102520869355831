<template>
  <v-list
    v-if="connections.length"
    id="sidebar-connections-group"
    v-model="selectedConnection"
    class="connections"
    nav
    :mandatory="!!selectedConnection"
    color="primary"
  >
    <div class="text-h6 mt-2 mb-2 text-center">Auth0 Connections</div>
    <v-list-item
      v-for="(connection, index) in connections"
      :key="index"
      :active="isOnConnectionsTab(connection)"
      :value="connection"
      @click="onConnectionClick(connection)"
    >
      <v-list-item-title>{{ connection.name }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import * as MutationTypes from '@/store/mutationTypes';
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters, mapState } from 'vuex';
import componentErrorHandler from '@/helpers/componentErrorHandler';

export default {
  name: 'Auth0Connections',
  extends: CSBase,
  props: {
    modelValue: { type: String, required: false, default: '' }
  },
  emits: ['update:modelValue'],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      connections: (state) => state.organization.connections
    }),
    ...mapGetters({
      isLoggedIn: 'authorization/isLoggedIn'
    }),
    selectedConnection: {
      get() {
        return this.$store.state.organization.selectedConnection;
      },
      set(value) {
        this.$store.commit(MutationTypes.ORGANIZATION_SET_SELECTED_CONNECTION, value);
      }
    }
  },
  watch: {
    selectedConnection(newValue) {
      if ((newValue && newValue.name !== this.modelValue) || (!newValue && this.modelValue)) {
        this.reportChanges(newValue);
      }
    },
    modelValue(newConnectionName) {
      this.resetConnection(newConnectionName);
    }
  },
  async mounted() {
    if (!this.isLoggedIn) {
      componentErrorHandler(this, undefined, 'user not logged in', false);
      this.$store.dispatch('authorization/logout');
      return;
    }

    try {
      if (this.connections.length === 0) {
        this.setLoading(true);
        await this.$store.dispatch('organization/fetchConnections');
        this.clearLoading();
      }

      const { connections } = this.$store.state.organization;
      if (connections.length === 0 || this.selectedConnection) {
        return;
      }
      if (!this.modelValue) {
        return;
      }
      this.resetConnection(this.modelValue);
    } catch (e) {
      componentErrorHandler(this, e, undefined, false);
    }
  },
  methods: {
    onConnectionClick(connection) {
      if (!this.selectedConnection || this.selectedConnection.id !== connection.id) {
        this.selectedConnection = connection;
      }
    },
    reportChanges(connection) {
      this.$emit('update:modelValue', connection.name);
    },
    resetConnection(connectionName) {
      if (this.isLoading()) {
        return;
      }
      if (!connectionName) {
        this.$store.commit(MutationTypes.ORGANIZATION_SET_SELECTED_CONNECTION, null);
        return;
      }
      const connection = this.connections.find((conn) => conn.name === connectionName) || null;
      if (!connection) {
        componentErrorHandler(
          this,
          undefined,
          `the page for ${connectionName} does not exist`,
          false
        );
      } else {
        this.$store.commit(MutationTypes.ORGANIZATION_SET_SELECTED_CONNECTION, connection);
      }
    },
    isOnConnectionsTab(connection) {
      return this.selectedConnection?.name === connection?.name;
    }
  }
};
</script>

<style scoped>
.v-list--nav {
  padding: 0;
  margin: 0;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}
</style>
