export default function isValidAppUrl(inputUrl) {
  if (typeof inputUrl !== 'string') {
    return false;
  }

  if (!inputUrl) {
    return true;
  }

  let url;
  try {
    url = new URL(inputUrl);
  } catch (_) {
    return false;
  }

  return (
    (url.protocol === 'http:' && process.env.VUE_APP_AUTH0_DOMAIN.includes('local-dev')) ||
    url.protocol === 'https:'
  );
}
