<template>
  <div v-if="usersView" class="users">
    <v-card>
      <v-card-title class="user-card-title">
        <v-spacer class="title-spacer"></v-spacer>
        <reset-user-passwords
          id="reset-user-passwords"
          class="bulk-action col-lg-auto"
          :connection-name="connectionName"
          :headers="selectedUsersHeaders"
          :selected="selected"
          @reset-users-password-completed="onBulkActionCompleted"
        />
        <assign-roles
          id="assign-roles"
          class="bulk-action col-lg-auto"
          :connection-name="connectionName"
          :selected="selected"
          @assign-roles-completed="onBulkActionCompleted"
        />
        <de-assign-roles
          id="de-assign-roles"
          class="bulk-action col-lg-auto"
          :connection-name="connectionName"
          :selected="selected"
          @de-assign-roles-completed="onBulkActionCompleted"
        />
        <delete-users
          id="delete-users"
          class="bulk-action col-lg-auto"
          :connection-name="connectionName"
          :headers="selectedUsersHeaders"
          :selected="selected"
          @delete-users-completed="onBulkActionCompleted"
        />
        <div class="export-users bulk-action col-lg-auto">
          <cs-button
            id="export-users-btn"
            :disabled="!canExportUsers"
            label="Export Users"
            @click.prevent="onExportUsersClick"
          />
        </div>

        <div class="create-user bulk-action col-lg-auto">
          <cs-button
            id="create-user-btn"
            primary
            :disabled="!connectionName || !organization"
            label="Create User"
            @click="onCreateUserClick"
          />
        </div>
      </v-card-title>
      <user-list
        id="users-user-list"
        v-model="selected"
        :connection-name="connectionName"
        :query-options="queryOptions"
        :users-outdated="usersOutdated"
        @on-query-options-change="onQueryOptionsChange"
        @users-updated="usersOutdated = false"
      />
    </v-card>
    <user-editor-dialog
      id="create-user-editor-dialog"
      v-model="showCreateDialog"
      heading="Create User"
      :connection-name="connectionName"
      :selected-roles="[]"
      :roles="roles"
      email=""
      name=""
      surname=""
      @submitted="refreshUsers"
    >
    </user-editor-dialog>
  </div>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';
import compact from 'lodash/compact';

import { mapGetters, mapState } from 'vuex';

import { csv, soul } from '@/dependency-injection';
import { ExportedUser } from '@/models';

import RouteName from '@/router/routeName';

import AssignRoles from '@/components/bulkActions/AssignRoles';
import DeAssignRoles from '@/components/bulkActions/DeAssignRoles';
import DeleteUsers from '@/components/bulkActions/DeleteUsers';
import ResetUserPasswords from '@/components/bulkActions/ResetUserPasswords';
import UserEditorDialog from '@/components/UserEditorDialog';
import UserList from '@/components/UserList';
import createDownloadableUrl from '@/helpers/createDownloadableUrl';

import componentErrorHandler from '@/helpers/componentErrorHandler';
import DateTime from '@/helpers/DateTime';

export default {
  name: 'UsersPage',

  components: {
    'assign-roles': AssignRoles,
    'de-assign-roles': DeAssignRoles,
    'delete-users': DeleteUsers,
    'user-editor-dialog': UserEditorDialog,
    'reset-user-passwords': ResetUserPasswords,
    'user-list': UserList
  },
  extends: CSBase,

  props: {
    connectionName: { type: String, required: false, default: '' },

    validationRules: {
      type: Object,
      default() {
        return {
          required: (label) => (v) => !!v || `${label} is required`,
          oneOf: (label, possibleValues) => (value) =>
            possibleValues.includes(value) || `${label} must be one of ${possibleValues.join(', ')}`
        };
      }
    }
  },

  data() {
    return {
      queryOptions: { itemsPerPage: 10, page: 1 },
      usersOutdated: false,
      selected: [],
      search: '',
      selectedUsersHeaders: [
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Surname',
          key: 'surname'
        },
        {
          title: 'Email',
          key: 'email'
        },
        {
          title: 'Roles',
          key: 'roles'
        }
      ],
      showCreateDialog: false,
      isExportingUsers: false
    };
  },

  computed: {
    ...mapState({
      organization: (state) => state.organization.organization,
      itemsLength: (state) => state.users.itemsLength
    }),

    ...mapGetters({
      appUrls: 'organization/appUrlsView',
      usersView: 'users/usersView',
      roles: 'organization/roles'
    }),

    canExportUsers() {
      if (this.isExportingUsers) {
        return false;
      }

      return this.itemsLength > 0;
    },

    hasAppUrls() {
      return Object.keys(this.appUrls).length > 0;
    }
  },

  watch: {
    async queryOptions(value) {
      const { page, itemsPerPage } = value;
      if (!this.isValidPage(page, itemsPerPage) && this.itemsLength) {
        await this.$router.push({
          name: RouteName.NOT_FOUND
        });
      }
    },

    async itemsLength(value) {
      const { page, itemsPerPage } = this.queryOptions;
      if (value && !this.isValidPage(page, itemsPerPage)) {
        await this.$router.push({
          name: RouteName.NOT_FOUND
        });
      }
    },

    async connectionName(newConnectionName, oldConnectionName) {
      if (newConnectionName && newConnectionName !== oldConnectionName) {
        this.initQueryOptions();
      }
    }
  },

  created() {
    this.initQueryOptions();
  },

  methods: {
    initQueryOptions() {
      const { query } = this.$route;
      const itemsPerPage = parseInt(query.itemsPerPage || '10', 10);
      const page = parseInt(query.page || '1', 10);
      const { order, sortBy, search } = query;
      this.queryOptions = { itemsPerPage, page, sortBy, order, search };
    },

    isValidPage(page, itemsPerPage) {
      const maxPage = Math.ceil(this.itemsLength / itemsPerPage || 10);

      return !!page && page > 0 && page <= maxPage;
    },

    isQueryOptionsChanged(queryOptions) {
      const currentPage = parseInt(this.$route.query.page, 10);
      const currentItemsPerPage = parseInt(this.$route.query.itemsPerPage, 10);
      const { sortBy, order, search } = queryOptions;

      return (
        currentItemsPerPage !== queryOptions.itemsPerPage ||
        currentPage !== queryOptions.page ||
        sortBy !== this.$route.query.sortBy ||
        order !== this.$route.query.order ||
        search !== this.$route.query.search
      );
    },

    async onQueryOptionsChange(queryOptions) {
      if (this.isQueryOptionsChanged(queryOptions)) {
        this.queryOptions = queryOptions;
        this.resetQueries(queryOptions);
      }
    },

    async resetQueries(queryOptions) {
      const { sortBy, order, search } = queryOptions;

      const query = {
        page: queryOptions.page,
        itemsPerPage: queryOptions.itemsPerPage
      };

      if (sortBy) {
        query.sortBy = sortBy;
        query.order = order;
      }

      if (search) {
        query.search = search;
      }

      await this.$router.push({
        name: RouteName.CONNECTION,
        params: {
          connectionName: this.connectionName
        },
        query
      });
    },

    refreshUsers() {
      this.usersOutdated = true;
    },

    async onExportUsersClick() {
      try {
        this.isExportingUsers = true;

        const columns = [
          {
            key: 'givenName',
            header: 'Given Name'
          },
          {
            key: 'surname',
            header: 'Surname'
          },
          {
            key: 'email',
            header: 'Email'
          },
          {
            key: 'roles',
            header: 'Roles'
          },
          {
            key: 'numberOfLogins',
            header: 'Number of Logins'
          }
        ];

        this.setLoading(true);

        const { sortBy, order } = this.queryOptions;

        const allUsers = await soul.getAllUsersByConnectionName(this.connectionName, sortBy, order);

        const exportableUsers = allUsers.map((it) =>
          ExportedUser(it.name, it.surname, it.email, it.roles, it.loginsCount)
        );

        const csvContents = csv.stringifyCsv(exportableUsers, columns);

        const href = createDownloadableUrl(csvContents);
        const download = this.getExportedUsersFilename();
        this.downloadFile(href, download);
        this.clearLoading();
        this.isExportingUsers = false;
      } catch (error) {
        this.isExportingUsers = false;
        componentErrorHandler(this, error, undefined, false);
      }
    },

    async onCreateUserClick() {
      this.showCreateDialog = true;

      if (!this.hasAppUrls) {
        await this.$nextTick();
        componentErrorHandler(
          this,
          undefined,
          'Please contact Complispace Admin to setup the app urls for the organization.',
          true
        );
      }
    },

    async onBulkActionCompleted() {
      this.selected = [];
      this.usersOutdated = true;
    },

    removeSelectedUserNotExistInUsersView(selected, usersView) {
      const usersViewEmails = usersView.items.map((it) => it.email);
      return selected.filter((it) => usersViewEmails.includes(it.email));
    },

    getExportedUsersFilename() {
      const organizationName = this.organization?.name || '';
      const timestamp = DateTime().stringifyAsTimestamp('Australia/Sydney');

      const parts = [organizationName, this.connectionName, timestamp];

      return compact(parts).join('-');
    },

    downloadFile(href, download) {
      const a = document.createElement('a');
      a.href = href;
      a.download = download;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
};
</script>
<style scoped>
.user-card-title {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

@media screen and (max-width: 1264px) {
  #export-users-btn {
    min-width: 100%;
  }

  #create-user-btn {
    min-width: 100%;
  }

  .user-card-title {
    flex-direction: column;
  }
}
</style>
