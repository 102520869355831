<template>
  <cs-view single-view>
    <template #content>
      <site-config-editor />
    </template>
  </cs-view>
</template>

<script>
import SiteConfigEditor from '@/components/SiteConfigEditor';

export default {
  name: 'SiteConfigPage',

  components: { 'site-config-editor': SiteConfigEditor }
};
</script>
