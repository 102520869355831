import * as MutationTypes from '@/store/mutationTypes';

const mutations = {
  [MutationTypes.SET_NAME](state, name) {
    state.name = name;
  },
  [MutationTypes.SET_APIVERSION](state, version) {
    state.apiVersion = version;
  }
};

export default mutations;
