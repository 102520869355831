import { Users } from '@/models';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  users: Users.create(),
  itemsLength: 0
};

const store = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
};

export default store;
