import Assert from '@/helpers/assert';

const Connection = (id, name, strategy) => {
  return {
    id: Assert.isPresent(id, 'id'),
    name: Assert.isPresent(name, 'name'),
    strategy: Assert.isPresent(strategy, 'strategy')
  };
};

export default Connection;
