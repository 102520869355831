<template>
  <cs-form-dialog
    :id="id"
    ref="invalid-connection-users-warning-dialog"
    class="invalid-connection-users-warning-dialog"
    :heading="heading"
    :primary-action="{ label: actionLabel }"
    :model-value="showDialog"
    @primary-click="onCancel"
    @update:model-value="onCancel"
  >
    <template #cs-form-dialog-content>
      <v-card v-if="invalidUsers.length > 0">
        <v-data-table
          :id="tableId"
          class="invalid-connection-users-table"
          :headers="headers"
          :items="invalidUsers"
        ></v-data-table>
      </v-card>
    </template>
  </cs-form-dialog>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';

export default {
  name: 'InvalidConnectionUsersWarningDialog',
  extends: CSBase,

  props: {
    actionLabel: {
      type: String,
      default() {
        return 'OK';
      }
    },

    connectionName: {
      type: String,
      required: true
    },

    heading: {
      type: String,
      default() {
        return 'Selected users with invalid connection';
      }
    },

    users: {
      type: Array,
      default: undefined,
      validator(arr) {
        if (!Array.isArray(arr)) {
          return false;
        }

        if (arr.length === 0) {
          return true;
        }

        return arr.every((it) => it.name && it.surname && it.email && it.connection);
      }
    },

    tableId: { type: String, required: false, default: '' },
    modelValue: Boolean
  },

  emits: ['dismiss', 'update:modelValue'],

  data() {
    return {
      id: this.$attrs.id,
      showDialog: this.modelValue,
      headers: [
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Surname',
          key: 'surname'
        },
        {
          title: 'Email',
          key: 'email'
        },
        {
          title: 'Connection',
          key: 'connection'
        }
      ]
    };
  },

  computed: {
    invalidUsers() {
      if (this.users.length > 0) {
        return this.users.filter((user) => user.connection !== this.connectionName);
      }

      return [];
    }
  },

  watch: {
    modelValue(val) {
      this.showDialog = val;
    }
  },

  methods: {
    closeDialog() {
      this.showDialog = false;
      this.$emit('update:modelValue', this.showDialog);
    },

    onCancel() {
      this.closeDialog();
      this.$emit('dismiss');
    }
  }
};
</script>
