import getters from './getters';
import actions from './actions';

const state = {};
const mutations = {};

const store = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default store;
