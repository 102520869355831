import Assert from '@/helpers/assert';
import { User } from '@/models';

const getUserById = (soulApiRequest) => async (id) => {
  Assert.isPresent(id, 'id');

  const response = await soulApiRequest({
    url: `/user/${encodeURIComponent(id)}`,
    method: 'get'
  });

  const { user: data } = response.data;

  return User(
    data.appMetadata,
    data.blocked,
    data.email,
    data.emailVerified,
    data.groups,
    data.id,
    data.identities,
    data.loginsCount,
    data.name,
    data.picture,
    data.roles,
    data.surname,
    data.userMetadata
  );
};

export default getUserById;
