const getVersion = (axiosPublic) => async () => {
  const axiosConfig = {
    method: 'GET',
    url: `/health/alive`
  };

  const response = await axiosPublic(axiosConfig);
  if (!response || !response.data || typeof response.data !== 'object') {
    throw new Error('Invalid response from soul API');
  }
  const { version } = response.data;
  return version;
};

export default getVersion;
