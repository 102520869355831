import FileReaderError from './FileReaderError';

const FILE_READER_READY_STATE_DONE = 2;

// See https://thecompetentdev.com/weeklyjstips/tips/65_promisify_filereader/
const readAsText = (file, reader) =>
  new Promise((resolve, reject) => {
    reader.addEventListener('load', (e) => {
      if (reader.readyState === FILE_READER_READY_STATE_DONE) {
        resolve(e.target.result);
      }
    });

    reader.addEventListener('error', () => {
      const error = new FileReaderError(
        'Failed to read text from file.',
        {
          name: file.name,
          type: file.type,
          size: file.size
        },
        reader.error ? reader.error.message : null
      );
      reject(error);
    });

    reader.readAsText(file);
  });

export default readAsText;
