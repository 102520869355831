const ASSIGN_ROLES_TO_USERS = 'assignRolesToUsers';
const DEASSIGN_ROLES_FROM_USERS = 'deAssignRolesFromUsers';
const DELETE_USERS = 'deleteUsers';
const RESET_USERS_PASSWORD = 'resetUsersPassword';
const CREATE_USERS = 'createUsers';

export default {
  ASSIGN_ROLES_TO_USERS,
  DEASSIGN_ROLES_FROM_USERS,
  DELETE_USERS,
  RESET_USERS_PASSWORD,
  CREATE_USERS
};
