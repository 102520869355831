const labelMapping = {
  policyConnect: 'Policy Connect',
  policyPlus: 'Policy Plus',
  assurance: 'Assurance',
  planCheckGo: 'Plan Check Go',
  safeTripBuilder: 'Safe Trip Builder',
  compliLearnPortal: 'CompliLearn',
  go1: 'Go1'
};

const getters = {
  appUrlsView(state) {
    const auth0UserDirectoryUrl = `${window.location.origin}/`;
    const { appUrls } = state.organization || {};
    const auth0UserDirectoryUrlLabel = `CS Auth0 User Directory - ${auth0UserDirectoryUrl}`;
    let urlWithLabel = {};
    if (appUrls && appUrls.length > 0) {
      urlWithLabel = appUrls.reduce((memo, appUrlObject) => {
        const [entry] = Object.entries(appUrlObject);
        const [key, value] = entry;

        const label = `${labelMapping[key]} - ${value}`;

        return {
          ...memo,
          [label]: value
        };
      }, {});
      return { [auth0UserDirectoryUrlLabel]: auth0UserDirectoryUrl, ...urlWithLabel };
    }

    return {};
  },

  appUrlsEditorView(state) {
    const { organization } = state;

    if (organization && organization.appUrls) {
      return organization.appUrls;
    }

    return undefined;
  },

  displayName(state) {
    const { organization } = state;
    return organization ? organization.displayName : undefined;
  },

  organizationId(state) {
    const { organization } = state;
    return organization ? organization.id : undefined;
  },

  roles(state) {
    if (!state.organization) {
      return [];
    }
    const { roleDefinitions: roles } = state.organization;
    if (!roles) {
      return [];
    }
    return roles;
  }
};

export default getters;
