import { soul } from '@/dependency-injection';
import * as MutationTypes from '@/store/mutationTypes';

const actions = {
  async fetchOrganizations({ commit }) {
    const organizations = await soul.getAllOrganizations();

    const itemsLength = organizations.length || 0;

    commit(MutationTypes.SET_ORGANIZATIONS, { organizations, itemsLength });
  }
};

export default actions;
