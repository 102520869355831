import readUploadedUsers from './readUploadedUsers';
import readUploadedUsersByKey from './readUploadedUsersByKey';
import removeUploadedUsers from './removeUploadedUsers';
import saveUploadedUsers from './saveUploadedUsers';
import saveUploadedUsersByKey from './saveUploadedUsersByKey';
import removeUploadedUsersByKey from './removeUploadedUsersByKey';

export const UPLOADED_USERS = 'uploadedUsers';
export const CREATED_UPLOADED_USERS = 'createdUploadedUsers';
export const FAILED_UPLOADED_USERS = 'failedUploadedUsers';
export const SKIPPED_UPLOADED_USERS = 'skippedUploadedUsers';

const uploadedUsers = (localStorage) => {
  return {
    getUploadedUsers: () => readUploadedUsers(localStorage, UPLOADED_USERS),
    saveUploadedUsers: (value) => saveUploadedUsers(localStorage, UPLOADED_USERS)(value),
    removeUploadedUsers: () => removeUploadedUsers(localStorage, UPLOADED_USERS),

    saveUploadedUsersByConnectionName: (connectionName, users) =>
      saveUploadedUsersByKey(localStorage, UPLOADED_USERS)(connectionName, users),
    getUploadedUsersByConnectionName: (connectionName) =>
      readUploadedUsersByKey(localStorage, UPLOADED_USERS)(connectionName),
    removeUploadedUsersByConnectionName: (connectionName) =>
      removeUploadedUsersByKey(localStorage, UPLOADED_USERS)(connectionName),

    getFailedUploadedUsers: () => readUploadedUsers(localStorage, FAILED_UPLOADED_USERS),
    saveFailedUploadedUsers: (value) =>
      saveUploadedUsers(localStorage, FAILED_UPLOADED_USERS)(value),
    removeFailedUploadedUsers: () => removeUploadedUsers(localStorage, FAILED_UPLOADED_USERS),

    getFailedUploadedUsersByConnectionName: (connectionName) =>
      readUploadedUsersByKey(localStorage, FAILED_UPLOADED_USERS)(connectionName),
    saveFailedUploadedUsersByConnectionName: (connectionName, users) =>
      saveUploadedUsersByKey(localStorage, FAILED_UPLOADED_USERS)(connectionName, users),
    removeFailedUploadedUsersByConnectionName: (connectionName) =>
      removeUploadedUsersByKey(localStorage, FAILED_UPLOADED_USERS)(connectionName),

    getCreatedUploadedUsers: () => readUploadedUsers(localStorage, CREATED_UPLOADED_USERS),
    saveCreatedUploadedUsers: (value) =>
      saveUploadedUsers(localStorage, CREATED_UPLOADED_USERS)(value),
    removeCreatedUploadedUsers: () => removeUploadedUsers(localStorage, CREATED_UPLOADED_USERS),

    getCreatedUploadedUsersByConnectionName: (connectionName) =>
      readUploadedUsersByKey(localStorage, CREATED_UPLOADED_USERS)(connectionName),
    saveCreatedUploadedUsersByConnectionName: (connectionName, users) =>
      saveUploadedUsersByKey(localStorage, CREATED_UPLOADED_USERS)(connectionName, users),
    removeCreatedUploadedUsersByConnectionName: (connectionName) =>
      removeUploadedUsersByKey(localStorage, CREATED_UPLOADED_USERS)(connectionName),

    getSkippedUploadedUsers: () => readUploadedUsers(localStorage, SKIPPED_UPLOADED_USERS),
    saveSkippedUploadedUsers: (value) =>
      saveUploadedUsers(localStorage, SKIPPED_UPLOADED_USERS)(value),
    removeSkippedUploadedUsers: () => removeUploadedUsers(localStorage, SKIPPED_UPLOADED_USERS),

    getSkippedUploadedUsersByConnectionName: (connectionName) =>
      readUploadedUsersByKey(localStorage, SKIPPED_UPLOADED_USERS)(connectionName),
    saveSkippedUploadedUsersByConnectionName: (connectionName, users) =>
      saveUploadedUsersByKey(localStorage, SKIPPED_UPLOADED_USERS)(connectionName, users),
    removeSkippedUploadedUsersByConnectionName: (connectionName) =>
      removeUploadedUsersByKey(localStorage, SKIPPED_UPLOADED_USERS)(connectionName)
  };
};

export default uploadedUsers;
