import * as MutationTypes from '@/store/mutationTypes';
import { soul } from '@/dependency-injection';

const actions = {
  async getApiVersion({ commit }) {
    const version = await soul.getVersion();
    commit(MutationTypes.SET_APIVERSION, version);
  }
};

export default actions;
