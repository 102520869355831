<template>
  <div class="role-definitions-editor">
    <div class="px-0 py-0">
      <div class="role-definitions-editor-title">
        <h3>{{ title }}</h3>
      </div>
      <v-container fluid>
        <v-combobox
          id="roles-combobox"
          v-model="model"
          multiple
          variant="solo"
          hint="Simply enter new role that you need and/or delete the role you don't need and then click the Apply Button."
          :disabled="!isCompliSpaceStaff"
          append-icon=""
        >
          <template #selection="{ item }">
            <v-chip
              v-if="item === Object(item)"
              label
              size="small"
              :class="item.value.new ? 'bg-warning' : ''"
            >
              <span class="pr-2 role-text">
                {{ item.value.text }}
              </span>
              <v-icon
                v-if="isCompliSpaceStaff"
                class="role-text-delete"
                size="small"
                @click="onRoleDeleteIcon(item)"
              >
                $delete
              </v-icon>
            </v-chip>
          </template>
        </v-combobox>
        <div v-if="isCompliSpaceStaff" class="edit-role-actions">
          <v-spacer></v-spacer>
          <cs-button
            id="save-role-definitions-btn"
            primary
            class="mb-2"
            :disabled="!dirty"
            label="Save"
            @click="onSaveRoleBtn"
          >
          </cs-button>
        </div>
      </v-container>
    </div>

    <cs-form-dialog
      id="role-definitions-editor-confirm-delete-dlg"
      v-model="confirmationDialog"
      heading="Delete Role Confirmation"
      :primary-action="{ label: 'No' }"
      :secondary-action1="{ label: 'Yes & Apply' }"
      @secondary1-click="onApply"
      @primary-click="confirmationDialog = false"
    >
      <template #cs-form-dialog-content>
        <div id="delete-confirmation-message">This action cannot be undone. Are you sure?</div>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      id="role-definitions-editor-confirm-stop-dlg"
      v-model="stopDialog"
      heading="Action cannot be done"
      text=""
      :primary-action="{ label: 'Ok' }"
      @primary-click="stopDialog = false"
    >
      <template #cs-form-dialog-content>
        <div id="stop-confirmation-message">
          This action cannot be done, because the role has been assigned to at least one user.
        </div>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters, mapMutations, mapState } from 'vuex';
import * as MutationTypes from '@/store/mutationTypes';
import OrganizationProp from '@/props/OrganizationProp';
import { Organization } from '@/models';
import { soul } from '@/dependency-injection';
import flattenDeep from 'lodash/flattenDeep';

export default {
  name: 'RoleDefinitionsEditor',

  extends: CSBase,

  props: {
    organization: {
      type: OrganizationProp,
      required: false,
      default: undefined
    }
  },

  data() {
    return {
      confirmationDialog: false,
      stopDialog: false,
      selectedRoleToBeConfirmDelete: null,
      selectedRoleToBeConfirmDeleteIsNew: false,
      roleDefinitions: []
    };
  },

  computed: {
    ...mapGetters({
      isCompliSpaceStaff: 'authorization/isCompliSpaceStaff',
      isClientAdmin: 'authorization/isClientAdmin'
    }),

    ...mapState({
      users: (state) => state.users.users,
      connections: (state) => state.organization.connections
    }),

    title() {
      const organizationDisplayName =
        (this.organization && this.organization.displayName) || undefined;

      if (organizationDisplayName && (this.isCompliSpaceStaff || this.isClientAdmin)) {
        return `${organizationDisplayName} roles`;
      }

      return '';
    },

    model: {
      get() {
        return this.roleDefinitions;
      },
      set(value) {
        const newRoles = value.filter((val) => typeof val === 'string');
        if (this.validateNewRoles(newRoles)) {
          this.roleDefinitions.push(...newRoles.map((role) => ({ text: role, new: true })));
          this.showUnsavedRoleDefinitionsAlert();
        }
      }
    },

    dirty() {
      return this.roleDefinitions.filter((roleDefinition) => roleDefinition.new).length > 0;
    }
  },

  watch: {
    organization: {
      handler(newVal) {
        if (newVal) {
          this.initializeRoleDefinitions();
          this.showUnsavedRoleDefinitionsAlert();
        }
      },
      immediate: true
    },
    roleDefinitions: {
      handler() {
        this.saveRoleDefinitionsToLocalStorage();
      },
      deep: true
    }
  },

  mounted() {
    this.showUnsavedRoleDefinitionsAlert();
    window.addEventListener('storage', this.syncRoleDefinitions);
  },

  beforeUnmount() {
    window.removeEventListener('storage', this.syncRoleDefinitions);
  },

  methods: {
    syncRoleDefinitions(event) {
      if (event.key === 'roleDefinitions') {
        this.roleDefinitions = JSON.parse(event.newValue || '[]');
      }
    },

    validateNewRoles(newRoles) {
      const existingRoles = this.roleDefinitions
        .filter((roleDefinition) => newRoles.includes(roleDefinition.text))
        .map((roleDefinition) => roleDefinition.text);
      if (existingRoles.length > 0) {
        this.showErrorAlert(`${existingRoles.join(', ')} already exists`);
        return false;
      }
      return true;
    },

    showUnsavedRoleDefinitionsAlert() {
      if (this.dirty) {
        this.showWarningAlert(
          `Unsaved Changes Alert: Items highlighted with a yellow background indicate unsaved changes. Please ensure you save them to avoid losing your work.`
        );
        return;
      }
      this.clearAlert();
    },

    initializeRoleDefinitions() {
      let storedRoleDefinitions = localStorage.getItem('roleDefinitions');
      this.roleDefinitions = this.organization.roleDefinitions.map((roleDefinition) => ({
        text: roleDefinition
      }));
      if (storedRoleDefinitions) {
        storedRoleDefinitions = JSON.parse(storedRoleDefinitions);
        this.roleDefinitions.push(
          ...storedRoleDefinitions.filter((roleDefinition) => roleDefinition.new)
        );
      }
    },

    saveRoleDefinitionsToLocalStorage() {
      localStorage.setItem('roleDefinitions', JSON.stringify(this.roleDefinitions));
    },

    deleteRoleDefinitionsFromLocalStorage() {
      localStorage.removeItem('roleDefinitions');
    },

    setNewOrganization() {
      const newRoleDefinitions = this.roleDefinitions.map((roleDefinition) => roleDefinition.text);
      const newOrganization = Organization(
        this.organization.id,
        this.organization.name,
        this.organization.displayName,
        newRoleDefinitions,
        this.organization.appUrls
      );
      this.setOrganization(newOrganization);
    },

    async onSaveRoleBtn() {
      this.setLoading(true);
      try {
        this.setNewOrganization();
        this.deleteRoleDefinitionsFromLocalStorage();
        await this.$store.dispatch('organization/saveRoleDefinitions');
        await this.$store.dispatch('organization/init');
        this.clearLoading();
        this.showSuccessAlert('Role Definitions are saved');
      } catch (err) {
        this.clearLoading();
        this.showErrorAlert(err.message, true);
      }
    },

    async onRoleDeleteIcon(roleItem) {
      const { text: roleToDelete, new: isNewRole } = roleItem.value;

      if (isNewRole) {
        this.selectedRoleToBeConfirmDelete = roleToDelete;
        this.selectedRoleToBeConfirmDeleteIsNew = true;
        this.confirmationDialog = true;
        return;
      }

      try {
        const tasks = this.connections.map((connection) =>
          soul
            .getAllUsersByConnectionName(connection.name)
            .then((users) => users.filterByRole(roleToDelete))
        );
        const filteredList = await Promise.all(tasks);
        const usersWithTheRole = flattenDeep(filteredList);
        if (usersWithTheRole.length > 0) {
          this.stopDialog = true;
        } else {
          this.selectedRoleToBeConfirmDelete = roleToDelete;
          this.selectedRoleToBeConfirmDeleteIsNew = false;
          this.confirmationDialog = true;
        }
      } catch (err) {
        console.error(err.message);
        this.showErrorAlert(
          'Internal Error occured when deleting the role: please contact Complispace Staff',
          true
        );
      }
    },

    deleteNewRole(role) {
      this.roleDefinitions = this.roleDefinitions.filter(
        (roleDefinition) => roleDefinition.text !== role || !roleDefinition.new
      );
    },

    async onApply() {
      this.setLoading(true);
      try {
        const role = this.selectedRoleToBeConfirmDelete;
        if (this.selectedRoleToBeConfirmDeleteIsNew) {
          this.deleteNewRole(role);
        } else {
          await soul.deleteRoleByOrganizationId(this.organization.id, role);
          await this.$store.dispatch('organization/init');
        }
        this.selectedRoleToBeConfirmDelete = null;
        this.selectedRoleToBeConfirmDeleteIsNew = false;
        this.confirmationDialog = false;
        await this.$nextTick();
        this.showSuccessAlert(`${role} is deleted`);
      } catch (err) {
        this.clearLoading();
        this.showErrorAlert(err.message, true);
      }
      this.clearLoading();
    },

    ...mapMutations({
      setOrganization: MutationTypes.ORGANIZATION_SET_ORGANIZATION
    })
  }
};
</script>

<style scoped>
.content {
  padding: 8px 16px;
}

.edit-role-actions {
  display: flex;
}

.container {
  padding: 0;
}

.role-definitions-editor-title {
  min-width: 1px;
  height: 32px !important;
}
</style>
