<template>
  <v-form ref="rbacl-editor-form" class="rbacl-editor-form">
    <h1 class="mt-2 mb-4">{{ productName }}</h1>
    <h4>Application Url: {{ hostName || '-' }}</h4>
    <span class="hint">{{ hintText }}</span>
    <div class="editor-textarea">
      <textarea :id="editorId"></textarea>
    </div>
    <div class="mt-4 actions">
      <cs-button
        id="create-or-update-rbacl-btn"
        :label="btnLabel"
        primary
        :disabled="!hostName"
        @click="onSaveBtn"
      />
    </div>
  </v-form>
</template>

<script>
import { toRaw } from 'vue';
import { CSBase } from '@complispace/cs-design-system';
import { mapState } from 'vuex';
import { soul } from '@/dependency-injection';
import componentErrorHandler from '@/helpers/componentErrorHandler';
import * as CodeMirror from 'codemirror';
import { NotFound } from '@/helpers/api-errors';

export default {
  name: 'RbaclEditor',
  extends: CSBase,

  props: {
    editorId: {
      type: String,
      required: true
    },
    productName: {
      type: String,
      required: true
    },
    hostName: {
      type: String,
      required: true
    },
    rbaclTemplate: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      editor: null,
      isCreate: true
    };
  },

  computed: {
    ...mapState({
      organization: (state) => state.organization.organization
    }),
    btnLabel() {
      return this.isCreate ? 'Create' : 'Update';
    },
    hintText() {
      return this.hostName
        ? 'You may update the application url in Application Urls tab.'
        : 'You need to set the application url for this product in Application Urls tab first.';
    }
  },

  watch: {
    async organization(newOrganization) {
      if (newOrganization) {
        await this.initEditor(toRaw(this.editor));
      }
    }
  },

  async mounted() {
    try {
      this.editor = CodeMirror.fromTextArea(document.getElementById(this.editorId), {
        lineNumbers: true,
        lineWrapping: true,
        mode: 'csacl'
      });
      this.editor.setSize('100%', 500);
      if (this.organization) {
        await this.initEditor(toRaw(this.editor));
      }
    } catch (err) {
      componentErrorHandler(this, err, undefined, false);
    }
  },

  methods: {
    async onSaveBtn() {
      try {
        this.setLoading(true);
        await soul.createOrUpdateRbaclByProductNameAndAppUrl(
          this.productName,
          this.hostName,
          this.editor.getValue()
        );
        this.showSuccessAlert(
          `${this.productName} RBACL for the client ${this.organization.displayName} updated successfully.`
        );
        this.isCreate = false;
      } catch (err) {
        componentErrorHandler(this, err, undefined, false);
      }

      this.clearLoading();
    },
    async initEditor(editor) {
      this.setLoading(true);
      if (!this.hostName) {
        componentErrorHandler(
          this,
          undefined,
          `Unable to load the RBACL for the ${this.productName} because of missing ${this.productName} App Url`,
          false
        );
        return;
      }

      try {
        const rbacl = await soul.getRbaclByProductNameAndAppUrl(this.productName, this.hostName);
        editor.setValue(rbacl);
        this.isCreate = false;
      } catch (err) {
        if (err instanceof NotFound) {
          editor.setValue(this.rbaclTemplate);
          this.isCreate = true;
          this.showWarningAlert(
            `No rbacl found for ${this.hostName}. You may create based on the template.`
          );
          this.clearLoading();
          return;
        }

        componentErrorHandler(this, err, undefined, false);
        editor.setValue('');
      }

      this.clearLoading();
    }
  }
};
</script>

<style scoped>
.editor-textarea {
  border-style: dotted;
  border-width: 1px;
}

.actions {
  text-align: right;
}

.hint {
  font-size: 0.875rem;
}
</style>
