import CsvParserError from './CsvParserError';
import FileReaderError from './FileReaderError';
import readAsText from './readAsText';

const parseCsv = (parser, reader) => async (file) => {
  try {
    const columns = ['Name', 'Surname', 'Email', 'ConnectionName', 'Roles'];
    const contents = await readAsText(file, reader);

    if (!contents) {
      throw new FileReaderError(
        'Cannot parse empty file.',
        {
          name: file.name,
          size: file.size,
          type: file.type
        },
        null
      );
    }

    return parser(contents, {
      relax_column_count_less: true,
      columns,
      on_record(record) {
        const requiredCols = ['Name', 'Surname', 'Email', 'ConnectionName'];
        const hasRequiredCols = requiredCols.every((col) => !!record[col]);

        if (!hasRequiredCols) {
          throw new CsvParserError(
            'Failed to parse csv.',
            'CSV_RECORD_EMPTY_COLUMN',
            'csv record has empty column',
            {
              name: file.name,
              size: file.size,
              type: file.type
            }
          );
        }

        if (!record.Roles) {
          return {
            Name: record.Name,
            Surname: record.Surname,
            Email: record.Email,
            ConnectionName: record.ConnectionName,
            Roles: ''
          };
        }

        return record;
      }
    });
  } catch (e) {
    if (e.name === 'FileReaderError' || e.name === 'CsvParserError') {
      throw e;
    } else {
      throw new CsvParserError('Failed to parse csv.', e.code, e.message, {
        name: file.name,
        size: file.size,
        type: file.type
      });
    }
  }
};

export default parseCsv;
