import Assert from '@/helpers/assert';

const deleteUserById = (soulApiRequest) => async (id) => {
  Assert.isPresent(id, 'id');

  const response = await soulApiRequest({
    url: `/user/${encodeURIComponent(id)}`,
    method: 'delete'
  });

  return response.data;
};

export default deleteUserById;
