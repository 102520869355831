<template>
  <div class="pt-2 organization">
    <div class="text-h5 ma-2 text-center">{{ organization && organization.displayName }}</div>
    <v-list id="organization-page-group" v-model="selectedRouteName" nav color="primary">
      <v-list-item
        :active="isOnRoleDefinitionsTab"
        :value="RouteName.ROLE_DEFINITIONS"
        @click="onRolesDefinitionClick"
      >
        <v-list-item-title class="role-definitions-title">Role Definitions</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isCompliSpaceStaff"
        :active="isOnAppUrlsTab"
        :value="RouteName.APP_URLS"
        @click="onAppUrlsClick"
      >
        <v-list-item-title class="app-urls-title">Application Urls</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isCompliSpaceStaff"
        :active="isOnRbaclPolicyConnectTab"
        :value="RouteName.RBACL_POLICY_CONNECT"
        @click="onPolicyConnectRbaclEditorClick"
      >
        <v-list-item-title class="rbacl-editor-title">
          PolicyConnect RBACL Editor
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isCompliSpaceStaff"
        :active="isOnRbaclSafeTripBuilderTab"
        :value="RouteName.RBACL_SAFE_TRIP_BUILDER"
        @click="onSafeTripBuilderRbaclEditorClick"
      >
        <v-list-item-title class="stb-rbacl-editor-title">
          SafeTripBuilder RBACL Editor
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters, mapState } from 'vuex';
import RouteName from '@/router/routeName';

export default {
  name: 'Auth0Organization',

  extends: CSBase,

  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      selectedRouteName: this.modelValue,
      RouteName
    };
  },

  computed: {
    ...mapState({
      organization: (state) => state.organization.organization
    }),

    ...mapGetters({
      isLoggedIn: 'authorization/isLoggedIn',
      isCompliSpaceStaff: 'authorization/isCompliSpaceStaff'
    }),

    isOnRoleDefinitionsTab() {
      return this.selectedRouteName === RouteName.ROLE_DEFINITIONS;
    },

    isOnAppUrlsTab() {
      return this.selectedRouteName === RouteName.APP_URLS;
    },

    isOnRbaclPolicyConnectTab() {
      return this.selectedRouteName === RouteName.RBACL_POLICY_CONNECT;
    },

    isOnRbaclSafeTripBuilderTab() {
      return this.selectedRouteName === RouteName.RBACL_SAFE_TRIP_BUILDER;
    }
  },

  watch: {
    modelValue(newPage) {
      if (newPage !== this.selectedRouteName) {
        this.selectedRouteName = newPage;
      }
    }
  },

  async mounted() {
    if (this.isLoggedIn) {
      this.setLoading(true);
      await this.$store.dispatch('organization/init');
      this.clearLoading();
    }
  },

  methods: {
    onRolesDefinitionClick() {
      this.selectedRouteName = RouteName.ROLE_DEFINITIONS;
      this.reportChanges(this.selectedRouteName);
    },

    onAppUrlsClick() {
      this.selectedRouteName = RouteName.APP_URLS;
      this.reportChanges(this.selectedRouteName);
    },

    onPolicyConnectRbaclEditorClick() {
      this.selectedRouteName = RouteName.RBACL_POLICY_CONNECT;
      this.reportChanges(this.selectedRouteName);
    },

    onSafeTripBuilderRbaclEditorClick() {
      this.selectedRouteName = RouteName.RBACL_SAFE_TRIP_BUILDER;
      this.reportChanges(this.selectedRouteName);
    },

    reportChanges(newPage) {
      this.$emit('update:modelValue', newPage);
    }
  }
};
</script>

<style scoped>
.v-list--nav {
  padding: 0;
  margin: 0;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}
</style>
