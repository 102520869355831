export function parseRoles(str, delimiter = ';') {
  return str.split(delimiter);
}

const parseUsers = (csv) => async (file) => {
  const contents = await csv.parseCsv(file);
  return contents.slice(1).map((row) => ({
    Name: row.Name,
    Surname: row.Surname,
    ConnectionName: row.ConnectionName,
    Email: row.Email,
    Roles: parseRoles(row.Roles)
  }));
};

export default parseUsers;
