export default {
  isLoggedIn: (...args) => {
    return args[3]['cs-session/isLoggedIn'];
  },

  isCompliSpaceStaff: (...args) => {
    const principal = args[3]['cs-session/user'];
    return principal && principal.isCompliSpaceStaff;
  },

  isClientAdmin: (...args) => {
    const principal = args[3]['cs-session/user'];
    return principal && principal.isClientAdmin;
  }
};
