import updateRoleByOrganizationId from '@/services/soul-api/updateRoleByOrganizationId';
import getOrganizationById from '@/services/soul-api/getOrganizationById';
import getUsersByConnectionName from '@/services/soul-api/getUsersByConnectionName';
import getConnectionsByOrganizationId from '@/services/soul-api/getConnectionsByOrganizationId';
import createUserByConnectionName from '@/services/soul-api/createUserByConnectionName';
import deleteRoleByOrganizationId from '@/services/soul-api/deleteRoleByOrganizationId';
import deleteUserById from '@/services/soul-api/deleteUserById';
import sendResetPwdEmailByUserId from '@/services/soul-api/sendResetPwdEmailByUserId';
import updateAppUrlsByOrganizationId from '@/services/soul-api/updateAppUrlsByOrganizationId';
import getUserById from '@/services/soul-api/getUserById';
import updateUserById from '@/services/soul-api/updateUserById';
import getAllUsersByConnectionName from './getAllUsersByConnectionName';
import getOrganizations from './getOrganizations';
import getAllOrganizations from './getAllOrganizations';
import assignRolesToUserById from './assignRolesToUserById';
import deAssignRolesFromUserById from './deAssignRolesFromUserById';
import getRbaclKeys from './getRbaclKeys';
import createOrUpdateRbaclByProductNameAndAppUrl from './createOrUpdateRbaclByProductNameAndAppUrl';
import getRbaclByProductNameAndAppUrl from './getRbaclByProductNameAndAppUrl';
import getAllAppUrls from './getAllAppUrls';
import getAuth0ConfigByAppUrl from './getAuth0ConfigByAppUrl';
import createOrUpdateAuth0ConfigByOrganizationId from './createOrUpdateAuth0ConfigByOrganizationId';
import getVersion from './getVersion';

export default function soul(soulApiRequest, axiosPublic) {
  return {
    getOrganizationById: getOrganizationById(soulApiRequest),
    updateRoleByOrganizationId: updateRoleByOrganizationId(soulApiRequest),
    updateAppUrlsByOrganizationId: updateAppUrlsByOrganizationId(soulApiRequest),
    updateUserById: updateUserById(soulApiRequest),
    getConnectionsByOrganizationId: getConnectionsByOrganizationId(soulApiRequest),
    createUserByConnectionName: createUserByConnectionName(soulApiRequest),
    deleteUserById: deleteUserById(soulApiRequest),
    getUserById: getUserById(soulApiRequest),
    getUsersByConnectionName: getUsersByConnectionName(soulApiRequest),
    getAllUsersByConnectionName: getAllUsersByConnectionName(soulApiRequest),
    deleteRoleByOrganizationId: deleteRoleByOrganizationId(soulApiRequest),
    assignRolesToUserById: assignRolesToUserById(soulApiRequest),
    deAssignRolesFromUserById: deAssignRolesFromUserById(soulApiRequest),
    sendResetPwdEmailByUserId: sendResetPwdEmailByUserId(soulApiRequest),
    getOrganizations: getOrganizations(soulApiRequest),
    getAllOrganizations: getAllOrganizations(soulApiRequest),
    rbaclKeys: getRbaclKeys(soulApiRequest),
    getRbaclByProductNameAndAppUrl: getRbaclByProductNameAndAppUrl(soulApiRequest),
    createOrUpdateRbaclByProductNameAndAppUrl:
      createOrUpdateRbaclByProductNameAndAppUrl(soulApiRequest),
    getAllAppUrls: getAllAppUrls(soulApiRequest),
    getAuth0ConfigByAppUrl: getAuth0ConfigByAppUrl(soulApiRequest),
    createOrUpdateAuth0ConfigByOrganizationId:
      createOrUpdateAuth0ConfigByOrganizationId(soulApiRequest),
    getVersion: getVersion(axiosPublic)
  };
}
