import * as MutationTypes from '@/store/mutationTypes';

const mutations = {
  [MutationTypes.SET_ORGANIZATION](state, organization) {
    state.organization = organization;
  },

  [MutationTypes.SET_CONNECTIONS](state, connections) {
    state.connections = connections;
  },

  [MutationTypes.SET_SELECTED_CONNECTION](state, selectedConnection) {
    state.selectedConnection = selectedConnection;
  }
};

export default mutations;
