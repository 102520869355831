<template>
  <!-- eslint-disable vue/no-v-model-argument -->
  <v-list
    :id="id"
    ref="user-roles-select-checkbox"
    v-model:selected="selectedRoles"
    class="user-roles-select-checkbox"
    :select-strategy="selectStrategy"
    variant="flat"
    @update:selected="onUpdateSelected"
  >
    <div v-if="header" class="roles-list-header">{{ header }}</div>
    <v-list-subheader v-if="hint">{{ hint }}</v-list-subheader>

    <div :id="groupId" class="roles-item-group">
      <v-list-item
        v-for="(role, index) in roles"
        :id="`${itemIdPrefix}-${index}`"
        :key="`${itemIdPrefix}-${index}`"
        class="role-list-item"
        :value="role"
        variant="plain"
      >
        <template #prepend="{ isActive }">
          <v-list-item-action start>
            <v-checkbox-btn
              :id="`${itemIdPrefix}-checkbox-${index}`"
              class="role-checkbox"
              :model-value="isActive"
              color="primary"
            ></v-checkbox-btn>
          </v-list-item-action>
        </template>
        <v-list-item-title :id="`${itemIdPrefix}-title-${index}`" class="role-list-item-title">
          {{ role }}
        </v-list-item-title>
      </v-list-item>
    </div>
  </v-list>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters } from 'vuex';

export default {
  name: 'UserRolesSelectCheckbox',
  extends: CSBase,

  props: {
    groupId: { type: String, required: false, default: '' },
    header: { type: String, required: false, default: '' },
    hint: { type: String, required: false, default: '' },

    itemIdPrefix: {
      type: String,
      default() {
        return 'organization-role';
      }
    },

    multiple: {
      type: Boolean,
      default() {
        return false;
      }
    },

    modelValue: {
      type: Array,
      default: () => [],
      validator(val) {
        if (!Array.isArray(val)) {
          return false;
        }

        if (val.length === 0) {
          return true;
        }
        return val.every((it) => typeof it === 'string' && it.length > 0);
      }
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      selectedRoles: this.modelValue,
      id: this.$attrs.id
    };
  },

  computed: {
    ...mapGetters({
      roles: 'organization/roles'
    }),
    selectStrategy() {
      return this.multiple ? 'classic' : 'single-independent';
    }
  },

  watch: {
    modelValue(val) {
      this.selectedRoles = val;
    }
  },

  async mounted() {
    if (!this.roles.length) {
      this.setLoading(true);
      await this.$store.dispatch('organizations/fetchOrganizations');
      this.clearLoading();
    }
  },

  methods: {
    onUpdateSelected(selectedRoles) {
      this.$emit('update:modelValue', selectedRoles);
    }
  }
};
</script>

<style scoped>
.roles-item-group {
  column-count: 2;
}

.role-list-item {
  break-inside: avoid;
}
</style>
