import Assert from '@/helpers/assert';
import getRbaclRequestUrl from '@/helpers/getRbaclRequestUrl';

const createOrUpdateRbaclByProductNameAndAppUrl =
  (soulApiRequest) => async (productName, appUrl, rbacl) => {
    Assert.isPresent(productName, 'productName');
    Assert.isPresent(appUrl, 'appUrl');
    Assert.isPresent(rbacl, 'rbacl');

    const url = getRbaclRequestUrl(productName);

    const response = await soulApiRequest({
      url,
      method: 'put',
      params: new URLSearchParams({ appUri: appUrl }),
      data: { rbacl }
    });

    return response.data;
  };

export default createOrUpdateRbaclByProductNameAndAppUrl;
