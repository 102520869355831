const getters = {
  usersView(state) {
    const { users } = state;
    const tableHeaders = [
      { title: 'Name', key: 'name', sortable: true },
      { title: 'Surname', key: 'surname', sortable: true },
      { title: 'Email', key: 'email', sortable: true },
      { title: 'No of Logins', key: 'loginsCount', sortable: false, width: '6%' },
      { title: 'Roles Assigned', key: 'roles', sortable: false, width: '15%' },
      { title: 'Connection Name', key: 'connection', sortable: false, width: '10%' },
      { title: 'Actions', key: 'actions', sortable: false, width: '6%' }
    ];

    const items = users.map((user) => {
      return {
        id: user.id, // this is hidden value we don't show Id on table
        name: user.name, // the key (name) must matched to tableHeaders value, so does following field
        surname: user.surname,
        email: user.email,
        loginsCount: user.loginsCount,
        roles: user.roles,
        connection: user.identities.length > 0 ? user.identities[0].connection : ''
        // we don't have actions in here because we will do it in v-data-table template
      };
    });

    return {
      headers: tableHeaders,
      items
    };
  }
};

export default getters;
