import { createApp, h } from 'vue';
import CSDesignSystem, { vuetify, translator } from '@complispace/cs-design-system';
import App from './App';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Assert from './helpers/assert';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'codemirror/lib/codemirror.css';

const options = {
  store,
  router,
  auth0: {
    appHost: window.location.host,
    appUrl: window.location.origin,
    auth0Domain: process.env.VUE_APP_AUTH0_DOMAIN,
    auth0ClientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    auth0Audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    appEnv: Assert.isPresent(process.env.VUE_APP_APP_ENV, 'VUE_APP_APP_ENV')
  }
};

const app = createApp({
  render: () => h(App)
});

const reqRes = require.context(`./resources/i18n`, true);
translator.applyAppI18n(reqRes);

app.use(CSDesignSystem, options);
app.use(vuetify);
app.use(router);
app.use(store);
app.mount('#app');
