<template>
  <cs-view single-view>
    <template #content>
      <rbacl-editor
        v-if="isCompliSpaceStaff"
        editor-id="policy-connect-editor"
        :product-name="productName"
        :host-name="hostName"
        :rbacl-template="rbaclTemplate"
      />
    </template>
  </cs-view>
</template>

<script>
import RbaclEditor from '@/components/RbaclEditor';
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters, mapState } from 'vuex';
import ProductName from '@/models/ProductName';
import componentErrorHandler from '@/helpers/componentErrorHandler';
import { PolicyConnectRbaclTemplate } from '@/resources/templates/rbacl.json';

export default {
  name: 'PolicyConnectRbaclEditor',
  components: { 'rbacl-editor': RbaclEditor },
  extends: CSBase,

  data() {
    return {
      productName: ProductName.PolicyConnect,
      rbaclTemplate: PolicyConnectRbaclTemplate
    };
  },

  computed: {
    ...mapState({
      organization: (state) => state.organization.organization
    }),
    ...mapGetters({
      isCompliSpaceStaff: 'authorization/isCompliSpaceStaff'
    }),
    hostName() {
      const appUrl =
        (this.organization && this.organization.appUrls.find((it) => it.policyConnect)) || '';
      if (!appUrl) {
        return '';
      }

      const url = new URL(appUrl.policyConnect);
      return url.port ? `${url.hostname}:${url.port}` : url.hostname;
    }
  },
  mounted() {
    if (!this.isCompliSpaceStaff) {
      componentErrorHandler(this, undefined, 'Only Complispace Staff can access this page.', false);
    }
  }
};
</script>
