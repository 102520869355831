import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
  organization: null,
  connections: [],
  selectedConnection: null
};

const store = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default store;
