import Assert from '@/helpers/assert';

const deleteRoleByOrganizationId = (soulApiRequest) => async (organizationId, role) => {
  Assert.isPresent(organizationId, 'organizationId');
  Assert.isPresent(role, 'role');

  const response = await soulApiRequest({
    url: `/organization/${organizationId}/role/${role}`,
    method: 'delete'
  });

  return response.data;
};

export default deleteRoleByOrganizationId;
