import Assert from '@/helpers/assert';

const AppUrl = (name, url) => {
  Assert.isPresent(name, 'name');
  const appUrl = {};
  appUrl[name] = Assert.isUrl(url, 'url');

  return appUrl;
};

export default AppUrl;
