import * as MutationTypes from '@/store/mutationTypes';
import { UploadedUsers } from '@/models';

const mutations = {
  [MutationTypes.SET_UPLOADED_USERS](state, { uploadedUsers, duplicatedUploadedUsers }) {
    state.uploadedUsers = uploadedUsers;
    if (duplicatedUploadedUsers) {
      state.duplicatedUploadedUsers = duplicatedUploadedUsers;
    }
  },

  [MutationTypes.RESET_UPLOADED_USERS](state) {
    state.uploadedUsers = UploadedUsers.create();
    state.duplicatedUploadedUsers = UploadedUsers.create();
    state.createdUploadedUsers = UploadedUsers.create();
    state.failedUploadedUsers = UploadedUsers.create();
    state.skippedUploadedUsers = UploadedUsers.create();
  },

  [MutationTypes.SET_CREATED_UPLOADED_USERS](state, createdUploadedUsers) {
    state.createdUploadedUsers = createdUploadedUsers;
  },

  [MutationTypes.SET_FAILED_UPLOADED_USERS](state, failedUploadedUsers) {
    state.failedUploadedUsers = failedUploadedUsers;
  },

  [MutationTypes.SET_SKIPPED_UPLOADED_USERS](state, skippedUploadedUsers) {
    state.skippedUploadedUsers = skippedUploadedUsers;
  }
};

export default mutations;
