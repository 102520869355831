<template>
  <div class="user-list">
    <v-card-subtitle>
      <v-text-field
        id="search-user-text-field"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        variant="underlined"
        color="primary"
        min-width="250px"
        @update:model-value="searchUsersDebounce"
      >
      </v-text-field>
    </v-card-subtitle>
    <v-data-table-server
      id="users-table"
      :model-value="selectedUsers"
      :page="options.page"
      :items-per-page="options.itemsPerPage"
      :items-per-page-options="[10, 25, 50]"
      :sort-by="options.sortBy"
      class="users-table"
      :headers="usersView.headers"
      :items="usersView.items"
      :items-length="itemsLength"
      :loading="setLoadingBar()"
      loading-text="Loading users..."
      show-select
      return-object
      dense
      @update:model-value="onSelectedUsersChange"
      @update:options="onUpdateOptions"
    >
      <template #item="{ item }">
        <tr
          class="users-table-row"
          @mouseover="onHoverItem(item)"
          @mouseleave="onLeaveHoverItem()"
          @focus="onHoverItem(item)"
          @blur="onLeaveHoverItem()"
        >
          <td class="pl-2">
            <v-checkbox
              :model-value="selectedUsers"
              :value="item"
              color="primary"
              multiple
              hide-details
              @update:model-value="onSelectedUsersChange"
            />
          </td>
          <td>{{ item.name }}</td>
          <td>{{ item.surname }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.loginsCount }}</td>
          <td>
            <div v-for="(role, index) in item.roles" :key="`role-${index}`">
              <div class="user-assigned-roles">
                <v-hover v-slot="{ isHovering, props }" open-delay="20">
                  <v-chip v-bind="props" class="ma-1" label size="small">
                    <span class="pr-2">
                      {{ role }}
                    </span>
                    <v-expand-transition>
                      <v-icon
                        v-if="isHovering"
                        class="float-right"
                        size="small"
                        @click="onRoleDeAssignIcon(item, role)"
                      >
                        $delete
                      </v-icon>
                    </v-expand-transition>
                  </v-chip>
                </v-hover>
                <v-expand-transition>
                  <v-btn
                    v-if="
                      role === item.roles[item.roles.length - 1] &&
                      hoveredItem &&
                      hoveredItem.id === item.id
                    "
                    size="x-small"
                    elevation="0"
                    icon
                    @click="onRoleAssignIcon(item)"
                  >
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </v-expand-transition>
              </div>
            </div>
            <v-expand-transition>
              <v-btn
                v-if="item.roles.length === 0 && hoveredItem && hoveredItem.id === item.id"
                size="x-small"
                elevation="0"
                icon
                @click="onRoleAssignIcon(item)"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-expand-transition>
          </td>
          <td>{{ item.connection }}</td>
          <td>
            <v-icon
              :id="`user-edit-action-${item.id}`"
              size="small"
              class="mr-2"
              @click="onEditUserClick(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              :id="`user-reset-password-action-${item.id}`"
              size="small"
              class="mr-2"
              @click="onSendResetPwdEmailClick(item)"
            >
              mdi-account-key
            </v-icon>
            <v-icon
              :id="`user-delete-action-${item.id}`"
              size="small"
              @click="onDeleteUserClick(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table-server>

    <user-editor-dialog
      id="edit-user-editor-dialog"
      v-model="showEditDialog"
      heading="Edit User"
      :connection-name="connectionName"
      :is-edit="!!user.id"
      :email="user.email"
      :user-id="user.id"
      :name="user.name"
      :selected-roles="user.roles"
      :surname="user.surname"
      :roles="roles"
      @submitted="onRefreshUsers"
      @close="onEditDialogClose"
    >
    </user-editor-dialog>

    <cs-form-dialog
      id="users-user-confirm-dlg"
      :model-value="confirmDialogMode !== enumConfirmDialog.HIDE"
      :heading="confirmDeleteDlgTitle"
      :primary-action="{
        label: 'No'
      }"
      :secondary-action1="{
        label: 'Yes'
      }"
      @update:model-value="onDeleteCancel"
      @secondary1-click="onDeleteConfirm"
      @primary-click="onDeleteCancel"
    >
      <template #cs-form-dialog-content>
        <div v-if="confirmDialogMode === enumConfirmDialog.DELETE_USER" id="confirmation-message">
          This action cannot be undone. Are you sure?
        </div>
        <div
          v-if="confirmDialogMode === enumConfirmDialog.DELETE_ROLE"
          id="confirmation-message-role"
        >
          De-assign role {{ selectedRole }} cannot be undone. Are you sure?
        </div>
      </template>
    </cs-form-dialog>

    <app-url-selector-dialog
      id="send-user-reset-pwd-email-confirm-dialog"
      ref="single-user-reset-password"
      v-model="showSendUserResetPwdEmailConfirmDialog"
      confirm-label="Confirm"
      cancel-label="Cancel"
      heading="Send User Reset Password Email Confirmation"
      hint="Please select the redirect URL that user will be navigated to after resetting the password."
      label="Application Url after reset password"
      selector-id="password-reset-redirect-url-select"
      @app-url-confirm="onSendResetUserPwdEmailConfirm"
      @app-url-cancel="onSendResetPwdEmailClose"
    ></app-url-selector-dialog>

    <user-assign-roles-dialog
      v-model="showUserAssignRolesDialog"
      :user-view="selectedUser"
      @user-roles-assigned="onUserRolesAssignedBtn"
    />
  </div>
</template>

<script>
import * as MutationTypes from '@/store/mutationTypes';

import { CSBase, CSFormDialog } from '@complispace/cs-design-system';

import debounce from 'lodash/debounce';
import pick from 'lodash/pick';

import { mapGetters, mapState } from 'vuex';

import { soul } from '@/dependency-injection';
import componentErrorHandler from '@/helpers/componentErrorHandler';

import AppUrlSelectorDialog from '@/components/AppUrlSelectorDialog';

import UserAssignRolesDialog from '@/components/UserAssignRolesDialog';
import UserEditorDialog from '@/components/UserEditorDialog';
import delay from '@/helpers/delay';

export const enumConfirmDialog = {
  HIDE: 0,
  DELETE_USER: 1,
  DELETE_ROLE: 2
};

export default {
  name: 'UserList',

  components: {
    'app-url-selector-dialog': AppUrlSelectorDialog,
    'user-editor-dialog': UserEditorDialog,
    'user-assign-roles-dialog': UserAssignRolesDialog,
    'cs-form-dialog': CSFormDialog
  },
  extends: CSBase,

  props: {
    connectionName: { type: String, required: false, default: '' },
    modelValue: { type: Array, default: () => [] },
    usersOutdated: { type: Boolean, default: true },
    queryOptions: {
      type: Object,
      default() {
        return { itemsPerPage: 10, page: 1 };
      }
    }
  },

  emits: ['update:modelValue', 'onQueryOptionsChange', 'usersUpdated'],

  data() {
    return {
      search: '',
      selectedUsers: this.modelValue || [],
      selectedUser: null,
      hoveredItem: null,

      options: {
        page: 1,
        itemsPerPage: 10
      },

      showEditDialog: false,

      user: {
        name: '',
        surname: '',
        email: '',
        roles: [],
        id: ''
      },

      showUserAssignRolesDialog: false,
      showSendUserResetPwdEmailConfirmDialog: false,
      redirectUrl: '',

      enumConfirmDialog,
      confirmDialogMode: enumConfirmDialog.HIDE,
      selectedRole: '',
      skipOptionUpdate: true
    };
  },

  computed: {
    ...mapState({
      organization: (state) => state.organization.organization,
      itemsLength: (state) => state.users.itemsLength
    }),

    ...mapGetters({
      appUrls: 'organization/appUrlsView',
      usersView: 'users/usersView',
      roles: 'organization/roles'
    }),

    canRequestPasswordReset() {
      const appUrlValues = Object.values(this.appUrls);
      return this.hasAppUrls && this.redirectUrl && appUrlValues.includes(this.redirectUrl);
    },

    hasAppUrls() {
      return Object.keys(this.appUrls).length > 0;
    },

    confirmDeleteDlgTitle() {
      if (this.confirmDialogMode === enumConfirmDialog.DELETE_ROLE) {
        return 'De-assign Role Confirmation';
      }
      return 'Delete User Confirmation';
    }
  },

  watch: {
    async connectionName(newConnectionName, oldConnectionName) {
      if (newConnectionName && newConnectionName !== oldConnectionName) {
        this.initTableOptions();
        await this.initUsers();
      }
    },

    usersView(newUsersView) {
      this.selectedUsers = this.removeSelectedUserNotExistInUsersView(
        this.selectedUsers,
        newUsersView
      );
    },

    modelValue(selected) {
      this.selectedUsers = selected;
    },

    async usersOutdated(usersOutdated) {
      if (usersOutdated) {
        await this.onRefreshUsers();
      }
    }
  },

  async mounted() {
    await this.initUsers();
    this.initTableOptions();
    await this.onRefreshUsers();
  },

  methods: {
    setLoadingBar() {
      return this.isLoading() ? 'primary' : false;
    },
    initTableOptions() {
      const itemsPerPage = parseInt(this.queryOptions.itemsPerPage || '10', 10);
      const page = parseInt(this.queryOptions.page || '1', 10);

      if (this.queryOptions.sortBy) {
        this.options = {
          itemsPerPage,
          page,
          sortBy: [
            {
              key: this.queryOptions.sortBy,
              order: this.queryOptions.order === 'desc' ? 'desc' : 'asc'
            }
          ]
        };
      } else {
        this.options = {
          itemsPerPage,
          page
        };
      }

      this.search = this.queryOptions.search;
    },

    isOptionsChanged(options) {
      const currentPage = parseInt(this.queryOptions.page, 10);
      const currentItemsPerPage = parseInt(this.queryOptions.itemsPerPage, 10);
      const { sortBy, order } = this.getOptionSortInfo(options);

      return (
        currentItemsPerPage !== options.itemsPerPage ||
        currentPage !== options.page ||
        sortBy !== this.queryOptions.sortBy ||
        order !== this.queryOptions.order ||
        this.search !== this.queryOptions.search
      );
    },

    async onUpdateOptions(options) {
      /* we have to skip the first emitted 'update:options' event because it was emitted with default options
      { page: 1, itemsPerPage: 10 } when it's mounted. It will overwrite the queryOptions that we have set with
      the default options. We need to prioritze the queryOptions when it is mounted. */
      if (this.skipOptionUpdate) {
        this.skipOptionUpdate = false;
        return;
      }

      if (this.isOptionsChanged(options)) {
        this.options = options;
        const { sortBy, order } = this.getOptionSortInfo(options);
        const { search } = this;

        this.$emit('onQueryOptionsChange', {
          itemsPerPage: options.itemsPerPage,
          page: options.page,
          sortBy,
          order,
          search
        });
        await this.onRefreshUsers();
      }
    },

    getOptionSortInfo(options) {
      const sortOption = options.sortBy?.[0];
      return {
        sortBy: sortOption?.key,
        order: sortOption?.order
      };
    },

    async onSearchUsersChange() {
      if (this.options.page > 1) {
        // reset the page, trigger updateOptions to reset queries
        this.options.page = 1;
        return;
      }
      this.onUpdateOptions(this.options);
    },

    async initUsers() {
      this.$store.commit(MutationTypes.USERS_SET_USERS_BY_CONNECTION_NAME, {
        users: [],
        itemsLength: 0
      });
      await this.onRefreshUsers();
    },

    async fetchUsers(options) {
      try {
        if (this.connectionName) {
          this.setLoading(true);

          const { sortBy, order } = this.getOptionSortInfo(this.options);
          const fetchOpt = options;

          if (sortBy) {
            fetchOpt.sortBy = sortBy;
            fetchOpt.order = order;
          }

          if (this.search) {
            fetchOpt.search = this.search;
          }

          await this.$store.dispatch('users/fetchUsersByConnectionName', fetchOpt);
          this.clearLoading();
          this.$emit('usersUpdated', true);
        }
      } catch (e) {
        componentErrorHandler(this, e, undefined, false);
      }
    },

    async onRefreshUsers(keepSelection = false) {
      const { itemsPerPage, page } = this.options;
      if (!keepSelection) {
        this.onSelectedUsersChange([]);
      }
      await delay(1500);

      await this.fetchUsers({
        itemsPerPage,
        page,
        connectionName: this.connectionName
      });
    },

    getInvalidConnectionMessage(user, connectionName) {
      return `Cannot update user with email ${user.email} that user connection ${user.connection} does not belong to the current connection ${connectionName}.`;
    },

    async showInvalidAppUrlsError() {
      await this.$nextTick();
      componentErrorHandler(
        this,
        undefined,
        'Please contact Complispace Admin to setup the app urls for the organization.',
        true
      );
    },

    deleteUser(user) {
      const { itemsPerPage, page } = this.options;
      const { sortBy, order } = this.getOptionSortInfo(this.options);

      const options = {
        id: user.id,
        itemsPerPage,
        page,
        connectionName: this.connectionName
      };

      if (sortBy) {
        options.sortBy = sortBy;
        options.order = order;
      }

      if (this.search) {
        options.search = this.search;
      }

      return this.$store.dispatch('users/deleteUserById', options);
    },

    async onEditUserClick(user) {
      if (user.connection !== this.connectionName) {
        const message = this.getInvalidConnectionMessage(user, this.connectionName);
        componentErrorHandler(this, undefined, message, false);

        return;
      }
      this.showEditDialog = true;

      if (!this.hasAppUrls) {
        await this.showInvalidAppUrlsError();
      }

      const editableUser = pick(user, ['name', 'surname', 'email', 'roles', 'id']);

      this.user.id = editableUser.id;
      this.user.name = editableUser.name;
      this.user.surname = editableUser.surname;
      this.user.email = editableUser.email;
      this.user.roles = editableUser.roles;
    },

    onDeleteUserClick(user) {
      if (user.connection !== this.connectionName) {
        const message = this.getInvalidConnectionMessage(user, this.connectionName);

        componentErrorHandler(this, undefined, message, false);

        return;
      }

      this.selectedUser = user;
      this.confirmDialogMode = enumConfirmDialog.DELETE_USER;
    },

    resetAppUrlSelector() {
      this.$refs['single-user-reset-password'].reset();
    },

    async onSendResetPwdEmailClick(user) {
      if (user.connection !== this.connectionName) {
        const message = this.getInvalidConnectionMessage(user, this.connectionName);
        componentErrorHandler(this, undefined, message, false);

        return;
      }

      this.selectedUser = user;
      this.showSendUserResetPwdEmailConfirmDialog = true;

      if (!this.hasAppUrls) {
        await this.showInvalidAppUrlsError();
      }
    },

    removeSelectedUserNotExistInUsersView(selected, usersView) {
      const usersViewEmails = usersView.items.map((it) => it.email);
      return selected.filter((it) => usersViewEmails.includes(it.email));
    },

    onSendResetPwdEmailClose() {
      this.selectedUser = null;
    },

    sendPasswordReset(userId, redirectUrl) {
      return soul.sendResetPwdEmailByUserId(userId, redirectUrl);
    },

    async onSendResetUserPwdEmailConfirm(value) {
      if (!this.selectedUser) {
        componentErrorHandler(
          this,
          undefined,
          'Make sure you click the action icon from the User List.',
          false
        );
        return;
      }

      this.redirectUrl = value;

      if (this.canRequestPasswordReset) {
        this.setLoading(true);
        try {
          await this.sendPasswordReset(this.selectedUser.id, this.redirectUrl);
          this.onSendResetPwdEmailClose();

          this.showSendUserResetPwdEmailConfirmDialog = false;
          this.resetAppUrlSelector();

          this.clearLoading();
          await this.$nextTick();
          this.showSuccessAlert('Reset Email has been sent to the user.');
        } catch (err) {
          componentErrorHandler(
            this,
            err,
            'Something unexpected happened. Please contact complispace.',
            true
          );
        }
      }
    },

    async onDeleteConfirm() {
      if (this.confirmDialogMode === enumConfirmDialog.DELETE_USER) {
        await this.deleteSelectedUser();
      } else if (this.confirmDialogMode === enumConfirmDialog.DELETE_ROLE) {
        await this.deAssignRole(this.selectedUser, this.selectedRole);
      }
      await this.onRefreshUsers(true);
    },

    async deleteSelectedUser() {
      if (this.confirmDialogMode === enumConfirmDialog.DELETE_USER && this.selectedUser) {
        try {
          this.clearAlert();
          this.setLoading(true);

          await this.deleteUser(this.selectedUser);
          this.clearLoading();
          this.selectedUser = null;

          this.confirmDialogMode = enumConfirmDialog.HIDE;

          this.showSuccessAlert('User deleted successfully.');
        } catch (e) {
          componentErrorHandler(this, e, 'Failed to delete user.', true);
        }
      }
    },

    onDeleteCancel() {
      this.confirmDialogMode = enumConfirmDialog.HIDE;
      this.clearLoading();
      this.clearAlert();
      this.selectedUser = null;
      this.selectedRole = '';
    },

    onEditDialogClose() {
      this.dialogTitle = '';
      this.showEditDialog = false;
      this.resetUser();
    },

    resetUser() {
      this.user = {
        name: '',
        surname: '',
        email: '',
        roles: [],
        id: ''
      };
    },

    onHoverItem(item) {
      this.hoveredItem = item;
    },

    onLeaveHoverItem() {
      this.hoveredItem = null;
    },

    async onRoleAssignIcon(userView) {
      this.selectedUser = userView;
      this.showUserAssignRolesDialog = true;
    },

    async onRoleDeAssignIcon(userView, role) {
      this.confirmDialogMode = enumConfirmDialog.DELETE_ROLE;
      this.selectedUser = userView;
      this.selectedRole = role;
    },

    async deAssignRole(userView, role) {
      this.setLoading(true);

      const user = await this.$store.dispatch('users/deAssignRoleByUserId', {
        userId: !!userView && userView.id,
        role
      });

      if (!user) {
        componentErrorHandler(
          this,
          undefined,
          `Failed to find user by id ${userView.id}, Please contact complispace staff`,
          false
        );
        return;
      }
      this.showSuccessAlert('Role de-assigned successfully');
      this.selectedUser = null;
      this.confirmDialogMode = enumConfirmDialog.HIDE;
      this.clearLoading();
    },

    async onUserRolesAssignedBtn() {
      this.onSelectedUsersChange([]);
      this.showSuccessAlert('Role(s) assigned successfully');
      this.showUserAssignRolesDialog = false;
    },

    onSelectedUsersChange(newSelected) {
      this.selectedUsers = newSelected;
      this.$emit('update:modelValue', newSelected);
    },

    searchUsersDebounce: debounce(function _() {
      this.onSearchUsersChange();
    }, 1000)
  }
};
</script>
<style scoped>
.v-data-table ::deep tr:hover {
  background: transparent !important;
}

/* mimic default vuetify style */
#users-loading-text,
#users-no-data-text {
  color: rgba(0, 0, 0, 0.38);
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}
</style>
