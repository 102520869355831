import { createWebHistory, createRouter } from 'vue-router';
import HomePage from '@/pages/HomePage';
import ApplicationUrls from '@/pages/ApplicationUrls';
import ConnectionPage from '@/pages/ConnectionPage';
import OrganizationsPage from '@/pages/OrganizationsPage';
import RoleDefinitions from '@/pages/RoleDefinitions';
import UploadUsersCsv from '@/pages/UploadUsersCsv';
import UsersPage from '@/pages/UsersPage';
import store from '@/store';
import PolicyConnectRbaclEditor from '@/pages/PolicyConnectRbaclEditor';
import SafeTripBuilderRbaclEditor from '@/pages/SafeTripBuilderRbaclEditor';
import NotFound from '@/pages/NotFound';
import SiteConfigPage from '@/pages/SiteConfigPage';
import RouteName from './routeName';

const ERROR_ALERT_AUTH_MESSAGE = 'errorAlertAuthMessage';

export function isAuthorizedStaff(vStore) {
  const isLoggedIn = vStore.getters['authorization/isLoggedIn'];
  const isCompliSpaceStaff = vStore.getters['authorization/isCompliSpaceStaff'];

  return isLoggedIn && isCompliSpaceStaff;
}

export const routes = [
  {
    path: '/',
    name: RouteName.HOME,
    component: HomePage
  },
  {
    path: '/connection',
    redirect: { name: 'Home' }
  },
  {
    path: '/connection/:connectionName',
    component: ConnectionPage,
    props: true,
    children: [
      {
        path: '',
        name: RouteName.CONNECTION,
        redirect: { name: 'Connection.Users' }
      },
      {
        path: 'users',
        name: RouteName.USERS,
        props: true,
        component: UsersPage
      },
      {
        path: 'upload-users-csv',
        name: RouteName.UPLOAD_USERS,
        component: UploadUsersCsv,
        props: true,
        beforeEnter(_1, _2, next) {
          if (isAuthorizedStaff(store)) {
            next();
          } else {
            localStorage.setItem(
              ERROR_ALERT_AUTH_MESSAGE,
              "You don't have enough permission as Client Administrator to access this page."
            );
            next('/');
          }
        }
      }
    ]
  },
  {
    path: '/organizations',
    name: RouteName.ORGANIZATIONS,
    component: OrganizationsPage,
    beforeEnter(to, from, next) {
      if (isAuthorizedStaff(store)) {
        next();
      } else {
        localStorage.setItem(
          ERROR_ALERT_AUTH_MESSAGE,
          "You don't have enough permission as Client Administrator to access this page."
        );
        next('/');
      }
    }
  },
  {
    path: '/site-config',
    name: RouteName.SITE_CONFIG,
    component: SiteConfigPage,
    beforeEnter(to, from, next) {
      if (isAuthorizedStaff(store)) {
        next();
      } else {
        localStorage.setItem(
          ERROR_ALERT_AUTH_MESSAGE,
          "You don't have enough permission as Client Administrator to access this page."
        );
        next('/');
      }
    }
  },
  {
    path: '/role-definitions',
    name: RouteName.ROLE_DEFINITIONS,
    component: RoleDefinitions
  },
  {
    path: '/app-urls-editor',
    name: RouteName.APP_URLS,
    component: ApplicationUrls
  },
  {
    path: '/rbacl/policy-connect',
    name: RouteName.RBACL_POLICY_CONNECT,
    component: PolicyConnectRbaclEditor
  },
  {
    path: '/rbacl/safe-trip-builder',
    name: RouteName.RBACL_SAFE_TRIP_BUILDER,
    component: SafeTripBuilderRbaclEditor
  },
  {
    path: '/:catchAll(.*)*',
    name: RouteName.NOT_FOUND,
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
