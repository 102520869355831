import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const state = {
  name: '',
  apiVersion: '0.0.1'
};
const store = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default store;
