const getRbaclKeys = (soulApiRequest) => async () => {
  const response = await soulApiRequest({
    url: '/rbacls/policy-connect',
    method: 'get'
  });

  return response.data.rbaclKeys;
};

export default getRbaclKeys;
