import Assert from '../../helpers/assert';
import BulkActionName from './bulkActionName';

const BulkActionMessageBuilder = {
  create: (bulkActionName, selectedUsers, failedUsers, successfulUsers) => {
    Assert.isPresent(bulkActionName, 'bulkActionName');
    Assert.isArray(selectedUsers, 'selectedUsers');

    if (selectedUsers.length === 0) {
      throw new Error('At least one user must be selected for a bulk action to be performed.');
    }

    Assert.isArray(failedUsers, 'failedUsers');
    Assert.isArray(successfulUsers, 'successfulUsers');

    const selectedUsersLen = selectedUsers.length;

    const successfulUsersLen = successfulUsers.length;
    const failedUsersLen = failedUsers.length;
    const processedUsersLen = successfulUsersLen + failedUsersLen;

    Assert.isPositiveNumber(processedUsersLen, 'processedUsersLen');

    let failedUsersMessage = '';

    if (failedUsersLen) {
      switch (bulkActionName) {
        case BulkActionName.ASSIGN_ROLES_TO_USERS:
          failedUsersMessage = `${failedUsersLen} of the ${processedUsersLen} failed when assigning roles to the selected users.`;
          break;

        case BulkActionName.CREATE_USERS:
          failedUsersMessage = `${failedUsersLen} of the ${processedUsersLen} failed when creating users.`;
          break;

        case BulkActionName.DEASSIGN_ROLES_FROM_USERS:
          failedUsersMessage = `${failedUsersLen} of the ${processedUsersLen} failed when de-assigning roles from the selected users.`;
          break;

        case BulkActionName.DELETE_USERS:
          failedUsersMessage = `${failedUsersLen} of the ${processedUsersLen} failed when deleting the selected users.`;
          break;

        case BulkActionName.RESET_USERS_PASSWORD:
          failedUsersMessage = `${failedUsersLen} of the ${processedUsersLen} failed when sending a reset password request for the selected users.`;
          break;

        default:
          failedUsersMessage = `${failedUsersLen} of the ${processedUsersLen} failed when performing the bulk action on the selected users.`;
      }
    }

    return {
      withCompleted: () => {
        switch (bulkActionName) {
          case BulkActionName.ASSIGN_ROLES_TO_USERS:
            return `Finished assigning roles to the selected users. ${failedUsersMessage}`.trim();

          case BulkActionName.CREATE_USERS:
            return `Finished creating users from the selected uploaded users. ${failedUsersMessage} Open the report to see more details.`.trim();

          case BulkActionName.DEASSIGN_ROLES_FROM_USERS:
            return `Finished de-assigning roles from the selected users. ${failedUsersMessage}`.trim();

          case BulkActionName.DELETE_USERS:
            return `Finished deleting the selected users. ${failedUsersMessage}`.trim();

          case BulkActionName.RESET_USERS_PASSWORD:
            return `Finished sending a reset password request for the selected users. ${failedUsersMessage}`.trim();

          default:
            return `Finished performing a bulk action on the selected users. ${failedUsersMessage}`.trim();
        }
      },

      withCanceled: () => {
        return `Finished processing ${processedUsersLen} of the ${selectedUsersLen} before the operation was canceled. ${failedUsersMessage}`.trim();
      }
    };
  }
};

export default BulkActionMessageBuilder;
