import Assert from '@/helpers/assert';

const createUserByConnectionName =
  (soulApiRequest) =>
  async (connectionName, email, name, surname, emailVerified, roles, redirectUrl) => {
    const user = {
      connectionName: Assert.isPresent(connectionName, 'createUser.connectionName'),
      email: Assert.isPresent(email, 'createUser.email'),
      name: Assert.isPresent(name, 'createUser.name'),
      surname: Assert.isPresent(surname, 'createUser.surname'),
      emailVerified: Assert.isPresent(emailVerified, 'createUser.emailVerified')
    };

    if (roles) {
      user.roles = Assert.isArray(roles, 'createUser.roles');
    }

    if (redirectUrl) {
      user.redirectUrl = Assert.isUrl(redirectUrl, 'createUser.redirectUrl');
    }

    const response = await soulApiRequest({ url: '/user', method: 'post', data: user });

    return response.data;
  };

export default createUserByConnectionName;
