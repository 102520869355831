<template>
  <v-row :id="id" class="progress-bar-row">
    <v-col class="progress-bar-cols" cols="12">
      <v-progress-linear
        :id="progressId"
        class="progress-bar"
        height="25"
        :model-value="progress"
        color="primary"
      >
        <template #default="{ value }">
          <strong class="progress-bar-text">{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progressBarId: { type: String, required: false, default: '' },
    modelValue: {
      type: Number,
      default: () => 0,
      required: false,
      validator(value) {
        const isNumber = typeof value === 'number' && !Number.isNaN(value);
        const isPercentage = value >= 0 && value <= 100;

        return isNumber && isPercentage;
      }
    }
  },

  data() {
    return {
      progress: this.modelValue || 0,
      id: this.$attrs.id,
      progressId: this.progressBarId
    };
  },

  watch: {
    modelValue(val) {
      this.progress = val || 0;
    }
  }
};
</script>
<style scoped></style>
