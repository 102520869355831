import * as MutationTypes from '@/store/mutationTypes';
import { soul } from '@/dependency-injection';

const actions = {
  async init({ rootGetters, commit, dispatch }) {
    await dispatch('auth0/buildClient', null, { root: true });
    const clientAppConfig = rootGetters['auth0/clientAppConfig'];
    if (clientAppConfig.organization) {
      const { organization: organizationId } = clientAppConfig;
      const organization = await soul.getOrganizationById(organizationId);
      commit(MutationTypes.SET_ORGANIZATION, organization);
    }
  },

  async saveRoleDefinitions({ state }) {
    if (state.organization) {
      await soul.updateRoleByOrganizationId(
        state.organization.id,
        state.organization.roleDefinitions
      );
    }
  },

  async fetchOrganization({ commit, state }) {
    if (state.organization) {
      const { id: organizationId } = state.organization;
      const organization = await soul.getOrganizationById(organizationId);
      commit(MutationTypes.SET_ORGANIZATION, organization);
    }
  },

  async fetchConnections({ rootGetters, commit, dispatch }) {
    await dispatch('auth0/buildClient', null, { root: true });
    const clientAppConfig = rootGetters['auth0/clientAppConfig'];
    if (clientAppConfig.organization) {
      const { organization: organizationId } = clientAppConfig;
      const response = await soul.getConnectionsByOrganizationId(organizationId, 'auth0');
      commit(MutationTypes.SET_CONNECTIONS, response.connections);
    }
  }
};

export default actions;
