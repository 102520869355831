import isFinite from 'lodash/isFinite';
import AssertError from './AssertError';

const Assert = {
  isPresent: (value, name) => {
    if (value === null || value === undefined) {
      throw new AssertError(`required value ${name} is not present.`);
    }
    return value;
  },

  isArray: (value, name) => {
    Assert.isPresent(value, name);
    if (!Array.isArray(value)) {
      throw new AssertError(`required value ${name} must be an array.`);
    }

    return value;
  },

  isUrl: (value, name) => {
    Assert.isPresent(value, name);
    try {
      // eslint-disable-next-line no-new
      new URL(value);
      return value;
    } catch (error) {
      throw new AssertError(`required value ${name} must be a valid url.`);
    }
  },

  isFiniteNumber: (value, name) => {
    Assert.isPresent(value, name);

    if (!isFinite(value)) {
      throw new AssertError(`required value ${name} must be a finite number.`);
    }

    return value;
  },

  isPositiveNumber: (value, name) => {
    Assert.isFiniteNumber(value, name);

    if (value <= 0) {
      throw new AssertError(`required value ${name} must be a positive number.`);
    }

    return value;
  }
};

export default Assert;
