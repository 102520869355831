import { soul } from '@/dependency-injection';
import * as MutationTypes from '@/store/mutationTypes';

const actions = {
  async fetchUsersByConnectionName(
    { commit },
    { connectionName, page, itemsPerPage, sortBy, order, search }
  ) {
    const { users, metadata } = await soul.getUsersByConnectionName(
      connectionName,
      itemsPerPage,
      page,
      sortBy,
      order,
      search
    );

    const { itemsLength } = metadata;

    commit(MutationTypes.SET_USERS_BY_CONNECTION_NAME, { users, itemsLength });
  },

  async deleteUserById(
    { dispatch },
    { id, connectionName, page, itemsPerPage, sortBy, order, search }
  ) {
    await soul.deleteUserById(id);
    await dispatch('fetchUsersByConnectionName', {
      connectionName,
      itemsPerPage,
      page,
      sortBy,
      order,
      search
    });
  },

  async deAssignRoleByUserId({ commit, state }, { userId, role }) {
    const user = state.users.findById(userId);
    if (!user) {
      return null;
    }

    await soul.deAssignRolesFromUserById(user, [role]);
    commit(MutationTypes.DE_ASSIGN_ROLE_BY_USER_ID, { userId: user.id, role });
    return state.users.findById(userId);
  },

  async assignRolesByUserId({ commit, state }, { userId, roles }) {
    const user = state.users.findById(userId);
    if (!user) {
      return null;
    }

    await soul.assignRolesToUserById(user, roles);
    commit(MutationTypes.ASSIGN_ROLES_BY_USER_ID, { userId: user.id, roles });
    return state.users.findById(userId);
  }
};

export default actions;
