<template>
  <cs-view single-view>
    <template #content>
      <v-container class="status-container fill-height justify-center">
        <div class="w-auto status">
          <div class="status-code text-h1 text-center mb-2 font-weight-bold">404</div>
          <div class="status-text mb-4 text-h5 text-center font-weight-bold">Not Found</div>
          <div class="message text-center">
            The resource requested could not be found on this server.
          </div>
        </div>
      </v-container>
    </template>
  </cs-view>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';

export default {
  name: 'NotFound',
  extends: CSBase
};
</script>
