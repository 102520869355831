import uniqBy from 'lodash/uniqBy';
import containsUppercase from '../helpers/containsUppercase';

const UploadedUsers = {
  create(userList) {
    const uploadedUsers = (userList || []).slice(0);

    uploadedUsers.getEmails = () => uploadedUsers.map((it) => it.getEmail());
    uploadedUsers.contains = (user) => {
      return uploadedUsers.some((it) => it.isSameEmail(user.email));
    };

    uploadedUsers.excludeByEmail = (others) => {
      const filtered = uploadedUsers.filter((user) => !others.contains(user));
      return UploadedUsers.create(filtered);
    };

    uploadedUsers.serialize = () => {
      return uploadedUsers.map((it) => it.serialize());
    };

    uploadedUsers.uniqByEmail = () => {
      return UploadedUsers.create(uniqBy(uploadedUsers, ({ email }) => email.toLowerCase()));
    };

    uploadedUsers.getUserByEmail = (email) => {
      return uploadedUsers.find((it) => it.isSameEmail(email));
    };

    uploadedUsers.concatenate = (other) => {
      return UploadedUsers.create(uploadedUsers.concat(other));
    };

    uploadedUsers.isEqual = (others) => {
      if (uploadedUsers.length !== others.length) {
        return false;
      }

      return uploadedUsers.every((user, index) => user.isEqual(others[index]));
    };

    uploadedUsers.removeUser = (user) => {
      return UploadedUsers.create(
        uploadedUsers.filter((it) => it.getEmail() !== user.email.toLowerCase())
      );
    };

    uploadedUsers.hasUpperCaseEmails = () => {
      const emails = UploadedUsers.create(uploadedUsers).getEmails();
      const uppercaseEmails = emails.filter(containsUppercase);

      return uppercaseEmails.length > 0;
    };

    return uploadedUsers;
  }
};

export default UploadedUsers;
