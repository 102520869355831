import Assert from '@/helpers/assert';
import { BadRequest } from '@/helpers/api-errors';

const updateUserById = (soulApiRequest) => async (id, name, surname, roles) => {
  Assert.isPresent(id, 'id');

  const user = {};

  if (name) {
    user.name = name;
  }

  if (roles) {
    Assert.isArray(roles, 'roles');
    user.roles = roles;
  }

  if (surname) {
    user.surname = surname;
  }

  const keys = Object.keys(user);

  if (keys.length === 0) {
    throw new BadRequest('must provide at least one of name, roles, surname to update the user.');
  }

  const response = await soulApiRequest({
    url: `/user/${encodeURIComponent(id)}`,
    method: 'patch',
    data: user
  });

  return response.data;
};

export default updateUserById;
