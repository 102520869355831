<template>
  <cs-form-dialog
    :model-value="showDialog"
    :heading="heading"
    :primary-action="{
      label: 'Confirm',
      eventName: 'form-submit',
      loading
    }"
    :secondary-action1="{
      label: 'Cancel',
      eventName: 'form-cancel',
      disabled: loading
    }"
    @update:model-value="onDialogClose"
    @primary-click="onSubmit"
    @secondary1-click="onDialogClose"
  >
    <template #cs-form-dialog-content>
      <v-form class="d-flex flex-column mt-7">
        <v-list lines="two">
          <v-list-item id="selected-app-url" title="Application Url" :subtitle="selectedAppUrl" />
          <v-list-item
            id="selected-org"
            title="Selected Organization"
            :subtitle="selectedOrganization?.displayName"
          />
        </v-list>
        <div class="d-flex flex-column flex-sm-row ml-3">
          <v-checkbox
            id="prompt-on-login-enabled-dialog-checkbox"
            v-model="selectedPromptOnLoginEnabled"
            label="Prompt on Login"
            color="primary"
            disabled
            density="compact"
            hide-details
          />
          <v-checkbox
            id="shared-device-enabled-dialog-checkbox"
            v-model="selectedSharedDeviceEnabled"
            label="Show Shared Device Option"
            color="primary"
            disabled
            density="compact"
            hide-details
          />
        </div>
        <v-checkbox
          id="connection-buttons-enabled-dialog-checkbox"
          v-model="selectedConnectionButtonsEnabled"
          class="ml-3"
          label="Connections Customization for Login"
          color="primary"
          disabled
          density="compact"
          hide-details
        />
        <v-list v-if="selectedConnectionButtonsEnabled" class="pt-0" lines="one">
          <v-list-item
            id="selected-connections-dialog"
            title="Selected Connections"
            :subtitle="selectedConnectionNames.join(', ')"
            lines="three"
          />
        </v-list>
      </v-form>
    </template>
  </cs-form-dialog>
</template>

<script>
import { CSBase, CSFormDialog } from '@complispace/cs-design-system';
import { soul } from '@/dependency-injection';

export default {
  name: 'SiteConfigEditorDialog',
  components: {
    'cs-form-dialog': CSFormDialog
  },
  extends: CSBase,
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    appUrl: {
      type: String,
      default: ''
    },
    organization: {
      type: Object,
      default: () => {}
    },
    promptOnLoginEnabled: {
      type: Boolean,
      required: true
    },
    sharedDeviceEnabled: {
      type: Boolean,
      required: true
    },
    connectionButtonsEnabled: {
      type: Boolean,
      required: true
    },
    connectionNames: {
      type: Array,
      default: () => []
    },
    isUpdate: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue', 'submitted'],
  data() {
    return {
      showDialog: false,
      selectedAppUrl: this.appUrl,
      selectedOrganization: this.organization,
      selectedPromptOnLoginEnabled: this.promptOnLoginEnabled,
      selectedSharedDeviceEnabled: this.sharedDeviceEnabled,
      selectedConnectionButtonsEnabled: this.connectionButtonsEnabled,
      selectedConnectionNames: this.connectionNames,
      loading: false
    };
  },
  watch: {
    modelValue(val) {
      this.showDialog = val;
    },
    appUrl(val) {
      this.selectedAppUrl = val;
    },
    organization(val) {
      this.selectedOrganization = val;
    },
    promptOnLoginEnabled(val) {
      this.selectedPromptOnLoginEnabled = val;
    },
    sharedDeviceEnabled(val) {
      this.selectedSharedDeviceEnabled = val;
    },
    connectionButtonsEnabled(val) {
      this.selectedConnectionButtonsEnabled = val;
      if (!val) {
        this.selectedConnectionNames = [];
      }
    },
    connectionNames: {
      handler(newValue) {
        this.selectedConnectionNames = newValue;
      },
      deep: true
    }
  },
  updated() {
    if (this.showDialog) {
      this.showWarningAlert(
        'Please contact the client admin to inform all users to log out and re-login via the login page again to refresh their browser configuration after updating site configuration.',
        true
      );
    }
  },
  methods: {
    onDialogClose() {
      this.showDialog = false;
      this.$emit('update:modelValue', false);
    },
    async onSubmit() {
      this.loading = true;
      try {
        await soul.createOrUpdateAuth0ConfigByOrganizationId(
          this.selectedAppUrl,
          this.selectedOrganization.id,
          this.selectedPromptOnLoginEnabled,
          this.selectedSharedDeviceEnabled,
          this.selectedConnectionButtonsEnabled,
          this.selectedConnectionNames
        );
        this.$emit('submitted');
      } catch (error) {
        this.showErrorAlert('Failed to create site configuration', true);
        this.loading = false;
        return;
      }
      this.loading = false;
      this.onDialogClose();
      this.showSuccessAlert(
        `Successfully ${this.isUpdate ? 'updated' : 'created'} site configuration`
      );
    }
  }
};
</script>
