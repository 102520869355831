const HOME = 'Home';
const CONNECTION = 'Connection';
const USERS = 'Connection.Users';
const UPLOAD_USERS = 'Connection.UploadUsersCsv';
const ORGANIZATIONS = 'Organizations';
const ROLE_DEFINITIONS = 'role-definitions';
const APP_URLS = 'app-urls';
const RBACL = 'RBACL';
const RBACL_POLICY_CONNECT = 'RBACL.PolicyConnect';
const RBACL_SAFE_TRIP_BUILDER = 'RBACL.SafeTripBuilder';
const NOT_FOUND = 'not-found';
const SITE_CONFIG = 'SiteConfig';

export default {
  HOME,
  CONNECTION,
  USERS,
  UPLOAD_USERS,
  ORGANIZATIONS,
  ROLE_DEFINITIONS,
  APP_URLS,
  RBACL,
  RBACL_POLICY_CONNECT,
  RBACL_SAFE_TRIP_BUILDER,
  NOT_FOUND,
  SITE_CONFIG
};
