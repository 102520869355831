<template>
  <cs-form-dialog
    :id="id"
    ref="duplicate-users-warning-dialog"
    class="user-emails-list-dialog"
    :model-value="showListDialog"
    :heading="heading"
    :primary-action="{ label: actionLabel }"
    @update:model-value="onCloseDialog"
    @primary-click="onCloseDialog"
  >
    <template #cs-form-dialog-content>
      <v-list v-if="users && users.length > 0" class="user-emails-list" variant="flat">
        <h4 v-if="message" class="user-emails-list-header">{{ message }}</h4>
        <v-list-item
          v-for="(user, index) in users"
          :key="`user-email-${index}`"
          :value="user"
          variant="flat"
        >
          <v-list-item-title>{{ user.email }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </cs-form-dialog>
</template>

<script>
export default {
  name: 'DuplicateUsersWarningDialog',

  props: {
    actionLabel: {
      type: String,
      default() {
        return 'OK';
      }
    },

    heading: {
      type: String,
      default: '',
      validator(val) {
        return typeof val === 'string' && val.length > 0;
      }
    },

    message: {
      type: String,
      default: '',
      validator(str) {
        return typeof str === 'string' && str.length > 0;
      }
    },

    users: {
      type: Array,
      default: undefined,
      validator(arr) {
        if (!Array.isArray(arr)) {
          return false;
        }

        if (!arr.length) {
          return true;
        }

        const hasEmail = (obj) =>
          obj && obj.email && typeof obj.email === 'string' && obj.email.length > 0;

        return arr.every(hasEmail);
      }
    },

    modelValue: { type: Boolean, required: true }
  },

  emits: ['dismiss'],

  data() {
    return {
      showListDialog: this.modelValue,
      id: this.$attrs.id
    };
  },

  watch: {
    modelValue(val) {
      this.showListDialog = val;
    }
  },

  methods: {
    onCloseDialog() {
      this.showListDialog = false;
      this.$emit('dismiss');
    }
  }
};
</script>
