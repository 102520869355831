import { UploadedUser, UploadedUsers } from '@/models';

const readUploadedUsersByKey = (localStorage, key) => (connectionName) => {
  const str = localStorage.getItem(key);

  const obj = str ? JSON.parse(str) : {};
  const users = obj[connectionName] || [];

  return UploadedUsers.create(
    users.map((user) =>
      UploadedUser(user.name, user.surname, connectionName, user.email, user.roles)
    )
  );
};

export default readUploadedUsersByKey;
