import Assert from '@/helpers/assert';

export default function UploadedUser(name, surname, connectionName, email, roles) {
  Assert.isPresent(name, 'name');
  Assert.isPresent(surname, 'surname');
  Assert.isPresent(email, 'email');
  Assert.isPresent(roles, 'roles');
  Assert.isPresent(connectionName, 'connectionName');

  return {
    name,
    surname,
    email,
    roles,
    connectionName,
    isEqual: (other) => {
      if (name !== other.name) {
        return false;
      }

      if (surname !== other.surname) {
        return false;
      }

      if (email.toLowerCase() !== other.email.toLowerCase()) {
        return false;
      }

      if (roles.length !== other.roles.length) {
        return false;
      }

      if (roles.sort().join(',') !== other.roles.sort().join(',')) {
        return false;
      }

      if (connectionName !== other.connectionName) {
        return false;
      }

      return true;
    },
    getEmail: () => email,
    isSameEmail: (value) => email.toLowerCase() === value.toLowerCase(),
    serialize: () => ({
      name,
      surname,
      email,
      roles,
      connectionName
    })
  };
}
