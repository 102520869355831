<template>
  <cs-form-dialog
    ref="user-assign-roles-dialog"
    :model-value="showDialog"
    heading="Assign Role(s)"
    :primary-action="{ label: 'Assign' }"
    :secondary-action1="{ label: 'Cancel' }"
    @update:model-value="onDialogCloseBtn"
    @primary-click="onAssignRolesBtn"
    @secondary1-click="onDialogCloseBtn"
  >
    <template #cs-form-dialog-content>
      <div class="container user-assign-role-form-container">
        <v-row>
          <v-col>
            <user-roles-select-checkbox
              id="user-roles-select"
              v-model="userSelectedRoles"
              group-id="user-editor-roles-group"
              header="Roles"
              hint="Assign roles to the user"
              multiple
            ></user-roles-select-checkbox>
          </v-col>
        </v-row>
      </div>
    </template>
  </cs-form-dialog>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';
import componentErrorHandler from '@/helpers/componentErrorHandler';
import UserRolesSelectCheckbox from './UserRolesSelectCheckbox';

export default {
  name: 'UserAssignRolesDialog',

  components: {
    'user-roles-select-checkbox': UserRolesSelectCheckbox
  },

  extends: CSBase,

  props: {
    modelValue: {
      type: Boolean,
      default: false
    },

    userView: {
      type: Object,
      default: undefined
    }
  },

  emits: ['user-roles-assigned', 'update:modelValue'],

  data() {
    return {
      showDialog: this.modelValue && !!this.userView,
      userSelectedRoles: (!!this.userView && this.userView.roles) || []
    };
  },

  watch: {
    modelValue(val) {
      this.showDialog = val;
    },
    userView(newUserView) {
      this.userSelectedRoles = (!!newUserView && newUserView.roles) || [];
    }
  },

  updated() {
    if (this.showDialog) {
      this.showWarningAlert(
        'Are you sure you want to assign roles. This action cannot be undone.',
        true
      );
    }
  },

  methods: {
    async onAssignRolesBtn() {
      try {
        this.setLoading(true);
        const userId = (!!this.userView && this.userView.id) || '';
        const user = await this.$store.dispatch('users/assignRolesByUserId', {
          userId,
          roles: this.userSelectedRoles
        });

        if (!user) {
          componentErrorHandler(
            this,
            undefined,
            `Failed to find user by id ${userId}, Please contact complispace staff`,
            false
          );
          return;
        }

        this.$emit('user-roles-assigned');
      } catch (err) {
        componentErrorHandler(this, err, 'Failed to assign role(s) to user', true);
      }

      this.clearLoading();
    },

    onDialogCloseBtn() {
      this.clearLoading();
      this.clearAlert();
      this.showDialog = false;
      this.$emit('update:modelValue', false);
    }
  }
};
</script>
<style scoped></style>
