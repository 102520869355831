import Assert from '@/helpers/assert';
import logApiError from '@/helpers/logApiError';
import apiErrorHandler from '@/helpers/apiErrorHandler';

export default (baseURL, axios) => {
  Assert.isPresent(baseURL, 'baseUrl');
  Assert.isPresent(axios, 'axios');

  return async (options) => {
    Assert.isPresent(options, 'options');

    Assert.isPresent(options.method, 'options.method');
    Assert.isPresent(options.url, 'options.url');

    try {
      const response = await axios({
        ...options,
        baseURL
      });

      return response;
    } catch (e) {
      logApiError(e);
      return apiErrorHandler(e);
    }
  };
};
