import Assert from '@/helpers/assert';

const sendResetPwdEmailByUserId = (soulApiRequest) => async (id, redirectUrl) => {
  Assert.isPresent(id, 'id');
  Assert.isPresent(redirectUrl, 'redirectUrl');

  const response = await soulApiRequest({
    url: `/user/${encodeURIComponent(id)}/reset-password`,
    method: 'post',
    data: {
      redirectUrl
    }
  });

  return response.data;
};

export default sendResetPwdEmailByUserId;
