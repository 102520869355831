<template>
  <cs-form-dialog
    :id="id"
    ref="progress-dialog"
    class="progress-dialog"
    :model-value="showProgressDialog"
    :heading="heading"
    :primary-action="{ label: cancelBtnLabel }"
    @primary-click="onCancel"
    @update:model-value="onCancel"
  >
    <template #cs-form-dialog-content>
      <div class="container dialog-container progress-dialog-container">
        <progress-bar :progress-bar-id="progressBarId" :model-value="percent"></progress-bar>
      </div>
    </template>
  </cs-form-dialog>
</template>

<script>
import { CSFormDialog } from '@complispace/cs-design-system';
import ProgressBar from './ProgressBar';

export default {
  name: 'ProgressDialog',
  components: {
    ProgressBar,
    'cs-form-dialog': CSFormDialog
  },

  props: {
    cancelBtnLabel: {
      type: String,
      default() {
        return 'Cancel';
      }
    },
    heading: {
      type: String,
      default: ''
    },
    progressBarId: {
      type: String,
      default: ''
    },
    progressPercent: {
      type: Number,
      default() {
        return 0;
      }
    },
    modelValue: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },

  emits: ['progress-cancel'],

  data() {
    return {
      showProgressDialog: this.modelValue,
      percent: this.progressPercent || 0,
      id: this.$attrs.id
    };
  },

  watch: {
    modelValue(val) {
      this.showProgressDialog = val;
    },
    progressPercent(val) {
      this.percent = val;
    }
  },

  methods: {
    closeDialog() {
      this.toggleProgressDialog(false);
      this.setProgressPercent(0);
    },

    setProgressPercent(value) {
      this.percent = value;
    },

    toggleProgressDialog(value) {
      this.showProgressDialog = value;
    },

    onCancel() {
      this.$emit('progress-cancel');
      this.closeDialog();
    }
  }
};
</script>
<style scoped></style>
