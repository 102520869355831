<script>
import { CSBase } from '@complispace/cs-design-system';

import { soul } from '@/dependency-injection';
import BulkActionMessageBuilder from '@/components/bulkActions/BulkActionMessageBuilder';
import componentErrorHandler from '@/helpers/componentErrorHandler';
import BulkActionName from './bulkActionName';
import delayForEnv from '../../helpers/delayForEnv';

export default {
  name: 'BulkActionBase',
  extends: CSBase,

  props: {
    selected: { type: Array, required: false, default: () => [] },
    connectionName: { type: String, required: false, default: '' }
  },

  data() {
    return {
      // init progress dialog.
      bulkActionName: '',

      // bulk action report:
      failedBulkActionUsers: [],
      hasBeenCanceled: false,
      isBulkActionInProgress: false,

      progressPercent: 0,
      successfulBulkActionUsers: [],
      showProgressDialog: false,
      showWarningDialog: false
    };
  },

  computed: {
    isSelected() {
      return this.selected.length > 0;
    },

    canRunBulkAction() {
      if (this.isBulkActionInProgress) {
        return false;
      }

      if (!this.isSelected) {
        return false;
      }

      return true;
    },

    containsUsersWithMismatchedConnections() {
      const invalidUsers = this.selected.filter((it) => it.connection !== this.connectionName);
      return invalidUsers.length > 0;
    }
  },

  methods: {
    getProgressPercent(index, len) {
      return 100 * ((index + 1) / len);
    },

    setProgressPercent(value) {
      this.progressPercent = value;
    },

    toggleProgressDialog(value) {
      this.showProgressDialog = value;
    },

    onProgressDialogCancel() {
      this.hasBeenCanceled = true;
      this.toggleProgressDialog(false);
    },

    initBulkActionProgress(bulkAction) {
      this.bulkActionName = bulkAction;

      this.hasBeenCanceled = false;

      this.successfulBulkActionUsers = [];
      this.failedBulkActionUsers = [];

      this.isBulkActionInProgress = true;

      this.setProgressPercent(0);
      this.toggleProgressDialog(true);
    },

    getBulkActionMessage() {
      const messageBuilder = BulkActionMessageBuilder.create(
        this.bulkActionName,
        this.selected,
        this.failedBulkActionUsers,
        this.successfulBulkActionUsers
      );

      return this.hasBeenCanceled ? messageBuilder.withCanceled() : messageBuilder.withCompleted();
    },

    async showBulkActionAlert(message, failedUsersLen) {
      await this.$nextTick();
      if (failedUsersLen > 0) {
        componentErrorHandler(this, undefined, message, false);
      } else {
        this.showSuccessAlert(message);
      }
    },

    clearBulkActionState() {
      this.bulkActionName = '';

      this.failedBulkActionUsers = [];
      this.successfulBulkActionUsers = [];

      this.hasBeenCanceled = false;
      this.isBulkActionInProgress = false;
    },

    runAction(user) {
      switch (this.bulkActionName) {
        case BulkActionName.ASSIGN_ROLES_TO_USERS:
          return soul.assignRolesToUserById(user, this.selectedRoles);

        case BulkActionName.CREATE_USERS:
          return soul.createUserByConnectionName(
            this.connectionName,
            user.email,
            user.name,
            user.surname,
            true,
            user.roles,
            this.redirectUrl
          );

        case BulkActionName.DEASSIGN_ROLES_FROM_USERS:
          return soul.deAssignRolesFromUserById(user, this.selectedRoles);

        case BulkActionName.DELETE_USERS:
          return soul.deleteUserById(user.id);

        case BulkActionName.RESET_USERS_PASSWORD:
          return soul.sendResetPwdEmailByUserId(user.id, this.redirectUrl);

        default:
          return Promise.reject(new Error('unknown bulk action.'));
      }
    },

    async runActionAndReport(user) {
      try {
        const p = await this.runAction(user);
        this.successfulBulkActionUsers.push(user);
        return p;
      } catch (e) {
        this.failedBulkActionUsers.push(user);
      }

      return undefined;
    },

    async runActionOnUsers(memo, user, index, arr) {
      await memo;
      if (this.hasBeenCanceled) {
        return undefined;
      }

      await this.runActionAndReport(user);
      const percent = this.getProgressPercent(index, arr.length);
      this.setProgressPercent(percent);

      if (index < arr.length - 1) {
        return delayForEnv();
      }

      return undefined;
    },

    async showUsersWithMismatchedConnectionError() {
      const message = `The users below cannot be updated as they do not belong to the connection ${this.connectionName}.`;
      componentErrorHandler(this, undefined, message, true);
    },

    dismissWarningDialog() {
      this.clearAlert();
      this.showWarningDialog = false;
    },

    async checkForUsersWithMismatchedConnections() {
      if (this.containsUsersWithMismatchedConnections) {
        this.showWarningDialog = true;
        await this.showUsersWithMismatchedConnectionError();
      }
    }
  },
  render: () => null
};
</script>
