import some from 'lodash/some';
import Assert from '@/helpers/assert';
import identity from './Identity';

const User = (
  appMetadata,
  blocked,
  email,
  emailVerified,
  groups,
  id,
  identities,
  loginsCount,
  name,
  picture,
  roles,
  surname,
  userMetadata
) => {
  return {
    appMetadata,
    blocked: Assert.isPresent(blocked, 'user.blocked'),
    email: Assert.isPresent(email, 'user.email'),
    emailVerified: Assert.isPresent(emailVerified, 'user.emailVerified'),
    groups: Assert.isArray(groups, 'user.groups'),
    id: Assert.isPresent(id, 'user.id'),
    identities: Assert.isArray(identities, 'user.identities').map((item) =>
      identity(item.connection, item.isSocial, item.provider, item.userId)
    ),
    loginsCount: Assert.isPresent(loginsCount, 'user.loginsCount'),
    name: Assert.isPresent(name, 'user.name'),
    picture: Assert.isPresent(picture, 'user.picture'),
    roles: Assert.isArray(roles, 'user.roles'),
    surname: Assert.isPresent(surname, 'user.surname'),
    userMetadata,
    hasRole: (role) => some(roles, (it) => it === role)
  };
};

export default User;
