import Assert from '@/helpers/assert';

const ExportedUser = (givenName, surname, email, roles, numberOfLogins) => {
  Assert.isPresent(givenName, 'exportedUser.givenName');
  Assert.isPresent(surname, 'exportedUser.surname');
  Assert.isPresent(email, 'exportedUser.email');
  Assert.isArray(roles, 'exportedUser.roles');
  Assert.isPresent(numberOfLogins, 'exportedUser.numberOfLogins');
  return {
    givenName,
    surname,
    email,
    roles: roles.join(';'),
    numberOfLogins
  };
};

export default ExportedUser;
