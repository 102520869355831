import Assert from '@/helpers/assert';

const updateRoleByOrganizationId = (soulApiRequest) => async (id, roles) => {
  Assert.isPresent(id, 'id');
  Assert.isPresent(roles, 'roles');

  const response = await soulApiRequest({
    url: `/organization/${id}`,
    method: 'patch',
    data: {
      roleDefinitions: roles
    }
  });

  return response.data;
};

export default updateRoleByOrganizationId;
