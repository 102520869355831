import Assert from './assert';

const pattern = new RegExp(
  // Domain part (including localhost, domain names, and IPv4 addresses)
  '^(((localhost)|((?:[a-z\\d](?:[a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})|' +
    '(?:(?:0{0,2}|1\\d{0,2}|2(?:[0-4]\\d|5[0-5])|[3-9]\\d?)\\.){3}' +
    '(?:0{0,2}|1\\d{0,2}|2(?:[0-4]\\d|5[0-5])|[3-9]\\d?))' +
    // Optional port part (e.g., :8080)
    '(?::(\\d{1,5}))?$',
  'i'
);

export default function isValidAuth0ConfigAppUrl(inputUrl) {
  if (typeof inputUrl !== 'string') {
    return false;
  }
  try {
    Assert.isUrl(`http://${inputUrl}`);
  } catch (error) {
    return false;
  }
  return pattern.test(inputUrl);
}
