<template>
  <div class="mb-6">
    <v-row class="pl-2">
      <v-col cols="12" md="5" style="height: 600px">
        <h5 class="pa-1">Selected Connections (Same order will show on login)</h5>
        <v-list
          class="drop-zone"
          elevation="1"
          @drop="onDrop($event, true)"
          @dragenter.prevent
          @dragover.prevent
        >
          <v-list-item
            v-for="(connection, index) in selectedConnections"
            :key="index"
            :title="connection"
            :prepend-icon="showLockIcon(connection)"
            append-icon="mdi-drag"
            :class="{ 'py-0': true, 'selected-connections': true, ...isDragging(connection) }"
            draggable="true"
            @dragstart="onDragStart($event, connection)"
            @dragend="onDragEnd()"
          ></v-list-item>
        </v-list>
      </v-col>
      <v-col cols="0" md="2" align="center" align-self="center">
        <v-icon>mdi-arrow-left-right</v-icon>
      </v-col>
      <v-col cols="12" md="5" style="height: 600px">
        <h5 class="pa-1">Available Connections</h5>
        <v-list
          class="drop-zone"
          elevation="1"
          @drop="onDrop($event, false)"
          @dragenter.prevent
          @dragover.prevent
        >
          <v-list-item
            v-for="(connection, index) in availableConnections"
            :key="index"
            :title="connection"
            append-icon="mdi-drag"
            :class="{ 'py-0': true, 'available-connections': true, ...isDragging(connection) }"
            draggable="true"
            @dragstart="onDragStart($event, connection)"
            @dragend="onDragEnd()"
          ></v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { soul } from '@/dependency-injection';

export default {
  name: 'ConnectionsSelector',
  extends: CSBase,

  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    organizationId: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedConnections: this.modelValue,
      availableConnections: [],
      draggingConnection: '',
      draggingFromSelected: false
    };
  },

  watch: {
    modelValue(val) {
      this.selectedConnections = val;
    },
    async organizationId(val) {
      await this.updateSelectedAndAvailableConnections(val);
    }
  },

  async mounted() {
    await this.updateSelectedAndAvailableConnections(this.organizationId);
  },

  methods: {
    isDragging(connection) {
      return { dragging: this.draggingConnection === connection };
    },
    showLockIcon(connection) {
      return connection === 'complispace-staff' ? 'mdi-lock' : '';
    },
    isComplispaceStaffConnection(connection) {
      return connection === 'complispace-staff';
    },
    onDragStart(event, item) {
      this.draggingConnection = item;
      /* eslint-disable-next-line no-param-reassign */
      event.dataTransfer.effectAllowed = 'move';
    },
    onDragEnd() {
      this.draggingConnection = '';
    },
    onDrop(event, toSelected) {
      if (this.isComplispaceStaffConnection(this.draggingConnection)) {
        this.showWarningAlert('complispace-staff connection cannot be moved.');
        return;
      }
      this.selectedConnections = this.selectedConnections.filter(
        (item) => item !== this.draggingConnection && !this.isComplispaceStaffConnection(item)
      );
      this.availableConnections = this.availableConnections.filter(
        (item) => item !== this.draggingConnection
      );
      if (!toSelected) {
        this.selectedConnections.push('complispace-staff');
        this.availableConnections.push(this.draggingConnection);
        this.$emit('update:modelValue', this.selectedConnections);
        return;
      }
      const selectedConnectionsElementsExcludingDragged = document.querySelectorAll(
        '.selected-connections:not(.dragging)'
      );
      this.addDraggedConnectionToSelectedConnections(
        event.clientY,
        selectedConnectionsElementsExcludingDragged
      );
      this.selectedConnections.push('complispace-staff');
      this.$emit('update:modelValue', this.selectedConnections);
    },
    addDraggedConnectionToSelectedConnections(mouseY, selectedElements) {
      if (!selectedElements.length) {
        this.selectedConnections.push(this.draggingConnection);
        return;
      }

      const elementsPositions = this.extractYPositionFromElements(selectedElements);
      elementsPositions.some((position, idx) => {
        if (mouseY < position) {
          this.selectedConnections.splice(idx, 0, this.draggingConnection);
          return true;
        }
        if (idx === elementsPositions.length - 1) {
          this.selectedConnections.push(this.draggingConnection);
          return true;
        }
        return false;
      });
    },
    extractYPositionFromElements(connectionElements) {
      const elementsPositions = [];
      connectionElements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        const position = Math.floor(rect.y + rect.height / 2);
        elementsPositions.push(position);
      });
      return elementsPositions;
    },
    async updateSelectedAndAvailableConnections(organizationId) {
      try {
        const { connections } = await soul.getConnectionsByOrganizationId(organizationId);
        const connectionNames = connections.map((connection) => connection.name);
        this.selectedConnections = this.selectedConnections.filter(
          (connection) =>
            connectionNames.includes(connection) && !this.isComplispaceStaffConnection(connection)
        );
        this.selectedConnections.push('complispace-staff');
        this.availableConnections = connectionNames.filter(
          (connection) => !this.selectedConnections.includes(connection)
        );
        this.$emit('update:modelValue', this.selectedConnections);
      } catch (error) {
        this.showErrorAlert('Failed to get connections by organization id');
      }
    }
  }
};
</script>

<style scoped>
.drop-zone {
  height: 550px;
  padding: 0;
}
.dragging {
  opacity: 0.3;
}
.selected-connections >>> .v-list-item-title {
  white-space: break-spaces !important;
}
.available-connections >>> .v-list-item-title {
  white-space: break-spaces !important;
}
</style>
