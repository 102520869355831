import isValidAuth0ConfigAppUrl from '@/helpers/isValidAuth0ConfigAppUrl';
import AssertError from '@/helpers/AssertError';

const getAuth0ConfigByAppUrl = (soulApiRequest) => async (appUrl) => {
  if (!isValidAuth0ConfigAppUrl(appUrl)) {
    throw new AssertError('Invalid url');
  }

  const params = new URLSearchParams({
    appUrl
  });

  const response = await soulApiRequest({
    url: '/auth0-config',
    method: 'get',
    params
  });
  return response.data.auth0Config;
};

export default getAuth0ConfigByAppUrl;
