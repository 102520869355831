export default class FileReaderError extends Error {
  constructor(message, fileMetadata, readerMessage) {
    super(message);

    this.name = 'FileReaderError';

    this.fileMetadata = fileMetadata; // { name, size, type }
    this.readerMessage = readerMessage;
  }
}
