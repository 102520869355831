import Assert from '@/helpers/assert';

const updateAppUrlsByOrganizationId =
  (soulApiRequest) =>
  async (
    id,
    policyConnectUrl,
    policyPlusUrl,
    assuranceUrl,
    planCheckGoUrl,
    safeTripBuilderUrl,
    compliLearnPortalUrl,
    go1Url
  ) => {
    Assert.isPresent(id, 'id');

    const appUrls = [];

    if (policyConnectUrl) {
      appUrls.push({
        policyConnect: Assert.isUrl(policyConnectUrl)
      });
    }

    if (policyPlusUrl) {
      appUrls.push({
        policyPlus: Assert.isUrl(policyPlusUrl)
      });
    }

    if (assuranceUrl) {
      appUrls.push({
        assurance: Assert.isUrl(assuranceUrl)
      });
    }

    if (planCheckGoUrl) {
      appUrls.push({
        planCheckGo: Assert.isUrl(planCheckGoUrl)
      });
    }

    if (safeTripBuilderUrl) {
      appUrls.push({
        safeTripBuilder: Assert.isUrl(safeTripBuilderUrl)
      });
    }

    if (compliLearnPortalUrl) {
      appUrls.push({
        compliLearnPortal: Assert.isUrl(compliLearnPortalUrl)
      });
    }

    if (go1Url) {
      appUrls.push({
        go1: Assert.isUrl(go1Url)
      });
    }

    const response = await soulApiRequest({
      url: `/organization/${encodeURIComponent(id)}`,
      method: 'patch',
      data: {
        appUrls
      }
    });

    return response.data;
  };

export default updateAppUrlsByOrganizationId;
